import { isLoginJWT } from "../../Utils/jwtUtils";

const initialState = {
  user: null,
  isLogin: false,
  azureToken: "",
  position: "",
  finishLogin: false,
};
export const userReducer = (state: any = initialState, action: any) => {
  switch (action.type) {
    case "@user/login":
      return {
        ...state,
        user: action.payload.user,
        position: action.payload.position,
        azureToken: action.payload.token,
        isLogin: true,
        finishLogin: action.payload.finishLogin,
      };
    case "@user/logout":
      return { ...state, user: {}, isLogin: false, azureToken: "" };
    default:
      return state;
  }
};
