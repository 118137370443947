import { Button, Form } from "react-bootstrap";
import { AedasLoading } from "../../../Shared/AedasLoading/AedasLoading";
import DeleteIcon from "@mui/icons-material/Delete";
import ModeEditIcon from "@mui/icons-material/ModeEdit";
import { Formik } from "formik";
import {
  createLocation,
  updateLocation,
} from "../../../../Services/LocationService";
import { useState } from "react";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import {
  addLocation,
  editLocation,
} from "../../../../Redux/Actions/Locations.action";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";

interface Props {
  locationsAll: any;
  initialValues: any;
  setSelectedLocation: any;
  setShowLocationModal: any;
  setLocationOnlySee: any;
}

export const Locations = ({
  locationsAll,
  initialValues,
  setSelectedLocation,
  setShowLocationModal,
  setLocationOnlySee,
}: Props) => {
  const dispatch = useDispatch();
  const [showNewLocationForm, setShowNewLocationForm] = useState(false);
  const [editingLocationId, setEditingLocationId] = useState<number | null>(
    null
  );

  const handleSubmitNewLocation = (values: any) => {
    createLocation(values).then((response: any) => {
      toast.success("Nueva ubicación añadida correctamente");
      setShowNewLocationForm(false);
      dispatch(addLocation(response));
    });
  };

  const handleSubmitEditLocation = (values: any) => {
    if (editingLocationId !== null) {
      updateLocation(values).then((response: any) => {
        toast.success("Ubicación actualizada correctamente");
        setEditingLocationId(null);
        dispatch(editLocation(response.data));
      });
    }
  };

  const handleEditLocationClick = (locationId: number) => {
    setEditingLocationId(locationId);
  };

  return (
    <>
      {!locationsAll ? (
        <AedasLoading />
      ) : (
        <div className="tab_colum">
          <span className="colum_title">Ubicaciones</span>
          <div className="positions-tabs">
            <Button
              className="save_button w-15"
              onClick={() => setShowNewLocationForm(true)}
              disabled={showNewLocationForm}
            >
              Añadir ubicación
            </Button>

            <div className="positions-container">
              {showNewLocationForm && (
                <Formik
                  initialValues={initialValues}
                  onSubmit={handleSubmitNewLocation}
                >
                  {({ values, handleChange, handleSubmit }) => (
                    <Form
                      className="position new-position"
                      onSubmit={handleSubmit}
                    >
                      <Form.Control
                        className="new-position-input"
                        type="text"
                        placeholder="Nombre de la nueva ubicación"
                        name="name"
                        value={values.name}
                        onChange={handleChange}
                      />
                      <Button className="save_button_two" type="submit">
                        Guardar
                      </Button>
                    </Form>
                  )}
                </Formik>
              )}
              {locationsAll?.map((location: any) => {
                return (
                  <div key={location.id} className="position_icons">
                    {editingLocationId === location.id ? (
                      <Formik
                        initialValues={{ id: location.id, name: location.name }}
                        onSubmit={handleSubmitEditLocation}
                      >
                        {({ values, handleChange, handleSubmit }) => (
                          <Form
                            className="position edit-position"
                            onSubmit={handleSubmit}
                          >
                            <Form.Control
                              className="edit-position-input"
                              type="text"
                              name="name"
                              value={values.name}
                              onChange={handleChange}
                            />
                            <input type="hidden" name="id" value={values.id} />
                            <Button className="save_button_two" type="submit">
                              Guardar
                            </Button>
                          </Form>
                        )}
                      </Formik>
                    ) : (
                      <div className="position">{location.name}</div>
                    )}
                    <div className="icons_container">
                      <RemoveRedEyeIcon
                        className="icon"
                        onClick={() => {
                          setSelectedLocation(location);
                          setShowLocationModal(true);
                          setLocationOnlySee(true);
                        }}
                      ></RemoveRedEyeIcon>
                      <DeleteIcon
                        onClick={() => {
                          setSelectedLocation(location);
                          setShowLocationModal(true);
                          setLocationOnlySee(false);
                        }}
                        className="icon"
                      ></DeleteIcon>
                      <ModeEditIcon
                        onClick={() => handleEditLocationClick(location.id)}
                        className="icon"
                      ></ModeEditIcon>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      )}
    </>
  );
};
