import { useState } from "react";
import { TableEdit } from "./TableEdit/TableEdit";
import { TableNormal } from "./TableNormal/TableNormal";
import { EventOnCellClick } from "../../../interface/events";

export function DataGridEditabled(props: any) {
  const {
    rows,
    columns,
    title,
    showHeader,
    singleIcon,
    openModal,
    openModalOnClickCell,
    handlePublish,
    modifiedHeight,
  } = props;
  const [edit, setEdit] = useState<boolean>(props.edit ? props.edit : false);

  return (
    <>
      {!edit ? (
        <TableNormal
          rows={rows}
          columns={columns}
          setEdit={setEdit}
          edit={showHeader}
          singleIcon={singleIcon}
          title={title}
          getRowId={props.getRowId}
          handlePublish={handlePublish}
          //SI NO FUNCIONA ALGUN CLICK ES POR ESTO PERO LUEGO REVISAR LO DEMAS
          onCellClick={(event: any) => {
            props.onCellClick(event);
          }}
          modifiedHeight={modifiedHeight}
          openModalOnClickCell={(event: EventOnCellClick) => {
            //Solo se abrira el modal si hay un boton en la columna de acciones.
            event.colDef.headerName === "Acciones" &&
              openModalOnClickCell(event);
          }}
        ></TableNormal>
      ) : (
        <TableEdit
          onCellClick={
            props.onCellClick &&
            ((event: any) => {
              props.onCellClick(event);
            })
          }
          onlyEdit={props.onlyEdit}
          handleSave={props.handleSave}
          checkSave={props.checkSave}
          openModal={openModal}
          rows={rows}
          columns={columns}
          setEdit={setEdit}
          showAddIcon={props.showAddIcon}
          title={title}
          modifiedHeight={modifiedHeight}
          getRowId={props.getRowId}
        ></TableEdit>
      )}
    </>
  );
}
