import { Search } from "@mui/icons-material";
import {
  IconButton,
  InputAdornment,
  TextField,
  useMediaQuery,
} from "@mui/material";
import { AedasLoading } from "../../../Components/Shared/AedasLoading/AedasLoading";
import { TYPE_ORDER } from "../../../Utils/typesCases";
import { useTabs } from "../../hooks/useTabs";
import { CompanyBox } from "../ComapnyBox/CompanyBox";
import { CompanyBoxDepartment } from "../CompanyBoxDepartment/CompanyBoxDepartment";
import CustomBox from "../CustomBox/CustomBox";
import { ICompany } from "../../../interface/services";
import "./Tab.css";
import { useMemo } from "react";

export function Tab() {
  const {
    isLoading,
    history,
    _handleKeyDown,
    search,
    handleChange,
    handleSearch,
    query,
    withoutResults,
    searchResult,
    AllData,
    companies,
  } = useTabs();
  const isMobile = useMediaQuery("(max-width: 600px)");
  const uuidKeyData = useMemo(() => crypto.randomUUID(), []);
  const uuidKeyCompanies = useMemo(() => crypto.randomUUID(), []);

  if (isLoading) {
    return <AedasLoading></AedasLoading>;
  }

  return (
    <div className="row" style={{ gap: "20px" }}>
      {/* COMIENZO DEL HEADER */}
      <header className="col-12 container_header">
        <div className="img_wrapper">
          <img
            alt=""
            className="img_background"
            src="/Assets/img/fondo_promocion.jpg"
          ></img>
        </div>

        <div className="header">
          <div
            className="header__banner"
            onClick={() => history.push("/organigrama")}
          >
            <img
              alt=""
              className="logo"
              src="Assets/img/LogoAedasSmall.svg"
            ></img>
            <h1 className="title_header" style={{ fontWeight: "bold" }}>
              Organigrama y Directorio
            </h1>
          </div>
          <div>
            <div className="search_container">
              <h1 className="search_title">Buscador</h1>
              <div style={{ width: "90%", marginTop: "10px" }}>
                <TextField
                  style={{ display: "flex", justifyContent: "flex-end" }}
                  id="input-with-icon-textfield"
                  fullWidth
                  label={
                    isMobile
                      ? "Nombre, Apellidos,Teléfono..."
                      : "Busca por Nombre, Apellidos, Email, Departamento o Teléfono"
                  }
                  variant="outlined"
                  onKeyDown={_handleKeyDown}
                  defaultValue={search}
                  onChange={handleChange}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="start">
                        <IconButton
                          onClick={handleSearch}
                          style={{ background: "white" }}
                        >
                          <Search></Search>
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      </header>
      {/* FINAL DEL HEADER */}
      {query.get("search") ? (
        <div
          className="d-flex justify-content-center align-items-center"
          style={{ width: "100%" }}
        >
          <div className="container_list">
            <h1 className="p-2 text-center font-weight-bold">Resultados</h1>
            <div
              className={`  ${
                withoutResults ||
                (searchResult === undefined
                  ? "employee_list"
                  : "employee_list employee_list--scroll")
              }`}
            >
              {withoutResults || searchResult === undefined ? (
                <h2>No se han encontrado resultados</h2>
              ) : (
                searchResult
                  ?.sort(function (a: any, b: any) {
                    if (a.name < b.name) {
                      return -1;
                    }
                    if (a.name > b.name) {
                      return 1;
                    }
                    return 0;
                  })
                  .map((employee: any) => {
                    return (
                      <div key={employee.id}>
                        <CustomBox
                          AllData={AllData}
                          employee={employee}
                        ></CustomBox>
                      </div>
                    );
                  })
              )}
            </div>
          </div>{" "}
        </div>
      ) : (
        <div className="col-12 company_department">
          <div className="container_list">
            <h1
              className="title_departments"
              style={{ fontWeight: "bold", padding: "15px" }}
            >
              AEDAS Homes Organigrama
            </h1>
            <div className="department_list">
              {AllData.sort((a: any, b: any) =>
                +a.position > +b.position ? 1 : -1
              ).map((card: any, i: number) => {
                const path = TYPE_ORDER[card.id_type];
                return (
                  <CompanyBoxDepartment
                    key={i + uuidKeyData}
                    name={card.name}
                    id={card.id_object}
                    path={path}
                  ></CompanyBoxDepartment>
                );
              })}
            </div>
            <div
              className="d-flex justify-content-center flex-wrap companies_list_update "
              style={{ gap: "20px", width: "100%" }}
            >
              {companies
                .sort((a: any, b: any) => (+a.position > +b.position ? 1 : -1))
                .filter(
                  (d: ICompany) =>
                    d.id !== 1 && d.position !== 0 && d.position !== null
                )
                .map((c: any, i: number) => {
                  return (
                    <CompanyBox
                      key={i + uuidKeyCompanies}
                      name={c.name}
                      id={c.id}
                      // path={"departamento"}
                    ></CompanyBox>
                  );
                })}
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
