import { useParams } from "react-router-dom";
import { AedasLoading } from "../../../Components/Shared/AedasLoading/AedasLoading";
import { OrgChartError } from "../shared/error/OrgChartError";
import { HeaderVisual } from "../shared/Header";
import { useOrgCompany } from "../../hooks/useOrgCompany";
import { useEffect, useState } from "react";
import { Leyend } from "../shared/Leyend";
import { findPeopleInDt } from "../../../Utils/findInDt";
import "./styles/Organigrama.css";
import NewOrg from "../org/NewOrg";
import { getNewOrgCompany } from "../../../Services/NewOrgService";

export function OrgChartCompany() {
  const params = useParams();
  const [leyend, setShowLeyend] = useState(false);
  const { company, error, organigrama } = useOrgCompany(params);

  const TITLE_ORGCHART = company.name;

  const [data, setData] = useState(null);
  const [isLoading, setisLoading] = useState(true);

  useEffect(() => {
    getNewOrgCompany(params.id).then((res) => {
      setData(res);
      setisLoading(false);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setShowLeyend(findPeopleInDt(organigrama));
  }, [organigrama]);

  if (isLoading) {
    return <AedasLoading></AedasLoading>;
  }
  return (
    <>
      <HeaderVisual title={TITLE_ORGCHART} />
      {/* GESTION DE ERROR DE ORGANIGRAMA */}
      {error ? (
        <OrgChartError />
      ) : (
        <>
          {leyend && <Leyend showLeyendDt={leyend} />}
          <div>
            <NewOrg data={data} idUser={params.employee}></NewOrg>
          </div>
        </>
      )}
    </>
  );
}
