import { IEmployee, ITags } from "../../interface/services";
import {
  duplicateEmployee,
  updateEmployee,
} from "../../Services/EmployeeConfig";
import { updateEmployeeTagArray } from "../../Services/EmployeeTagService";

export function useEditEmployee(props: any) {
  const { show, employee, employees, isLoading, employeeTags } = props;

  const giveDefaultValues = (employee: IEmployee) => {
    // const isInDT: boolean = employee.in_dt;
    const company: any = props.selectedCompany ?? "";
    const delegation: any = props.selectedDelegation ?? "Sin delegación";
    const tagsEmployee = employeeTags.map((tag: any) => {
      const id_employee = employee.id;
      const id_tag = tag.id_tag;
      const id_manager = tag.id_manager;
      return { id_employee, id_tag, id_manager };
    });
    return { company, delegation, tagsEmployee };
  };

  //Actualiza empleado, compañia y delegacion
  const employeeUpdate = async (values: any) => {
    const newEmployee = {
      ...employee,
      in_comite_inversiones: values.in_comite_inversiones,
      in_comite_negocio: values.in_comite_negocio,
      in_comite_cumplimiento: values.in_comite_cumplimiento,
      in_comite_esg: values.in_comite_esg,
      delegacion_provincial: values.delegation ? values.delegation.id : null,
      id_company: values.company.id,
      id_internal_position: values.id_internal_position,
      id_location: values.id_location,
    };

    const employeeIndex = findEmployeeIndexById(employees, newEmployee.id);
    const updatedEmployees = [...employees];
    updatedEmployees[employeeIndex] = newEmployee;

    await updateEmployee(newEmployee).then(() => {
      setTimeout(() => {
        window.location.reload();
      }, 500);
    });
  };

  const findEmployeeIndexById = (employees: any, employeeId: any) => {
    return employees.findIndex((emp: any) => emp.id === employeeId);
  };

  //Actualiza las etiquetas.
  const updateTags = ({ tags }: { tags: ITags[]; manager: any }) => {
    const tagsUpdated = tags.map((tag: any) => {
      const id_tag = tag.id_tag?.id;
      const id_manager = tag.id_manager?.id;
      const altura = +tag.altura;
      return { ...tag, id_tag, id_manager, altura };
    });

    updateEmployeeTagArray(tagsUpdated);
  };

  const handleSubmit = (values: any) => {
    employeeUpdate(values);
    //Si se ha intertado alguna etiqueta actualiza las tags sino no

    values.tags[0].id_tag && updateTags(values);
    show(!show);
    isLoading(!isLoading);
  };

  const handleDuplicateEmployee = () => {
    duplicateEmployee(employee);
    show(!show);
    isLoading(!isLoading);
  };

  return {
    handleSubmit,
    handleDuplicateEmployee,
    giveDefaultValues,
  };
}
