import { MainListItems } from "../LisHeaderProvaider/listItems";
import IconButton from "@mui/material/IconButton";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";

import "./Header.css";
import { PropsHeader } from "../../../interface/header";

export function Header(props: PropsHeader) {
  const { menu, setIsOpen, open } = props;

  const handleOpenClose = () => {
    setIsOpen(!open);
  };

  return (
    <div
      style={{
        width: open ? "300px" : "70px",
        height: "100%",
        backgroundColor: "white",
        position: "fixed",
        overflow: "hidden",
      }}
    >
      <div className="d-flex justify-content-center w-100">
        <img src="/Assets/img/LogoAedasSmall.svg" height={70}></img>
        {open ? (
          <div className="d-flex align-items-center">
            <h1 className="appName">Organigrama</h1>
          </div>
        ) : (
          <></>
        )}
      </div>
      <div className="openCloseButton_container">
        <IconButton onClick={handleOpenClose} className="openCloseButton">
          {open ? (
            <ArrowBackIosIcon></ArrowBackIosIcon>
          ) : (
            <ArrowForwardIosIcon></ArrowForwardIosIcon>
          )}
        </IconButton>
      </div>

      <MainListItems
        setOpen={setIsOpen}
        open={open}
        menu={menu}
      ></MainListItems>
    </div>
  );
}
