export function searchTree(element: any, matchingID: any):  any | null {
  if (element.assistant !== null || element.assistant !== undefined) {
    if (element?.assistant?.id.toString() === matchingID) {
      element.assistant.selected = true;
      return element;
    }
  }
  if (element.id !== null || element.id !== undefined) {
    if (element?.id.toString() === matchingID) {
      element.selected = true;
      return element;
    } else if (element.children != null && element.children.length > 0) {
      let i;
      let result = null;
      for (i = 0; result == null && i < element.children.length; i++) {
        result = searchTree(element.children[i], matchingID);
      }
      return result;
    }
  }

  return null;
}
