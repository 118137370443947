import { SyntheticEvent, useEffect, useState } from "react";
import { toast } from "react-toastify";
import {
  getScheduledDraft,
  publishDraft,
  removeScheduledDraft,
} from "../Services/OrganizationChartService";
import { useIsMounted } from "./useIsMounted";
import { useHistory } from "react-router-dom";

export function usePublishOrg(id_mongo: string) {
  const history = useHistory();
  const isMounted = useIsMounted();
  const [openModalShow, setOpenModalShow] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState(true);
  const [schedulePublish, setSchedulePublish] = useState<any>();

  useEffect(() => {
    if (isLoading) {
      getScheduledDraft()
        .then((response: any) => {
          isMounted &&
            setSchedulePublish(
              response.data.find(
                (d: any) => d.id_mongo === id_mongo && d.publish === false
              )
            );
          setIsLoading(false);
        })
        .catch(() => {
          setSchedulePublish(null);
          setIsLoading(false);
        });
    }
  }, [isLoading]);

  const handleSchedulePublish = (e: SyntheticEvent) => {
    setOpenModalShow(!openModalShow);
  };

  const handleRemoveScheduledPublish = (e: SyntheticEvent) => {
    removeScheduledDraft(schedulePublish.id).then(() => {
      toast.success("Se ha cancelado la programación");
      setIsLoading(true);
    });
  };

  const handlePublish = (companyId: number) => {
    toast.info("El borrador se esta publicando");
    publishDraft(companyId)
      .then(() => {
        toast.success("El borrador se ha publicado correctamente.");
        history.push(`/organigramas/organigrama-actual/${companyId}`);
      })
      .catch((error) => {
        toast.error("No se pudo publicar el borrador, intentalo más tarde.");
      });
  };

  return {
    schedulePublish,
    setOpenModalShow,
    openModalShow,
    isLoading,
    setIsLoading,
    handlePublish,
    handleSchedulePublish,
    handleRemoveScheduledPublish,
  };
}
