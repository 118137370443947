import moment from "moment";
import { useState, useEffect } from "react";
import { Tab, TabList, Tabs, TabPanel } from "react-tabs";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { EmployeeAssignment } from "./EmployeeAssigations/EmployeeAssignment";
import { PublishOrg } from "./PublishOrg/PublishOrg";
import { ICompany, IDraftOrgChart } from "../../../../../interface/services";
import { getCompanyByID } from "../../../../../Services/CompanyService";
import { getDraftsOrgChart } from "../../../../../Services/OrganizationChartService";
import { AedasLoading } from "../../../../Shared/AedasLoading/AedasLoading";
import { ShowOrganizationChart } from "../../../../Shared/ShowOrganigramaChart/ShowOrganizationChart";

export const EditDraftOrgChart = () => {
  const params: any = useParams();
  const [date, setDate] = useState(moment().format("DD-MM-YYYY"));
  const [isLoading, setIsLoading] = useState(true);
  const [company, setCompany] = useState<ICompany | null>(null);
  const [draft1, setDraft1] = useState<any>(null);

  const company_id = params.company_id;

  useEffect(() => {
    setIsLoading(true);
  }, [params.template_id]);

  useEffect(() => {
    if (isLoading) {
      getCompanyByID(company_id)
        .then((response: ICompany) => {
          setCompany(response);
          getDraftsOrgChart(params.template_id, company_id)
            .then((response: IDraftOrgChart) => {
              setDraft1(response);
              setIsLoading(false);
            })
            .catch((error) => {
              setIsLoading(false);
              if (error.status === 404) {
                console.error("Not found");
              } else {
                toast.error("Ha habido un error");
              }
            });
        })
        .catch((error) => {
          if (error.status === 404) {
            setIsLoading(false);
          } else {
            toast.error("Ha habido un error");
          }
        });
    }
  }, [isLoading]);

  return (
    <>
      {isLoading ? (
        <AedasLoading></AedasLoading>
      ) : (
        <>
          <h1 className="main-title orgChart__title">{company?.name}</h1>
          <div className="px-4 h-100">
            <Tabs>
              <TabList>
                <Tab onClick={() => setIsLoading(true)}>Organigrama</Tab>
                <Tab>Empleados</Tab>
                <Tab>Publicar</Tab>
                <p className="date">Fecha: {date} </p>
              </TabList>

              <TabPanel>
                <ShowOrganizationChart
                  orgChart={draft1?.data ?? draft1}
                  setOrgChart={setDraft1}
                />
              </TabPanel>

              <TabPanel>
                <EmployeeAssignment
                  id_mongo={params.template_id}
                  company={company}
                />
              </TabPanel>

              <TabPanel>
                <PublishOrg
                  company={company}
                  date={date}
                  id_mongo={params.template_id}
                />
              </TabPanel>
            </Tabs>
          </div>
        </>
      )}
    </>
  );
};
