/**
 * Funcion que recorre un Organigrama, Si se encuentra la propiedad in_dt en cualquier nivel del objeto, muestra la leyenda (Barra naranja).
 * @param obj Organigrama a comprobar
 * @returns True / False segun encuentre o no alguien con la propiedad in_dt en true
 */
export function findInDt(obj: any) {
  if (obj?.in_dt) {
    // Si la propiedad in_dt es true, cambia el valor de la variable a true y devuelve
    return true;
  }
  // Si la propiedad in_dt es false o no existe, busca en las propiedades anidadas
  for (let i = 0; i < obj?.children?.length; i++) {
    if (findInDt(obj?.children[i])) {
      // Si se encuentra la propiedad in_dt en cualquier nivel anidado, cambia el valor de la variable a true y devuelve
      return true;
    }
  }
  // Si no se encuentra la propiedad in_dt en este objeto ni en sus propiedades anidadas, devuelve false
  return false;
}

/**
 * Funcion que recorre un Organigrama(array, hecho para las tags que es un array de objetos), Si se encuentra la propiedad in_dt en cualquier nivel del array, muestra la leyenda (Barra naranja).
 * @param obj Organigramas(array de organigramas) a comprobar
 * @returns True / False segun encuentre o no alguien con la propiedad in_dt en true
 */
export function findPeopleInDt(data: any) {
  if (Array.isArray(data)) {
    for (let i = 0; i < data.length; i++) {
      if (findInDt(data[i])) {
        return true; // Devuelve true si se encuentra la propiedad in_dt en cualquier nivel de cualquier objeto en la matriz
      }
    }
    return false;
  } else {
    return findInDt(data);
  }
}
