import axios from "axios";
import { toast } from "react-toastify";
import { configAuth } from "../Utils/headersConfig";

export const getAllDepartments = () => {
  return axios
    .get(
      process.env.REACT_APP_API_BACKEND + "/api/v1" + "/department/",
      configAuth()
    )
    .then((res) => {
      return res.data;
    })
    .catch((error) => toast.error("Error al cargar el departamento"));
};
