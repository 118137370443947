import moment from "moment";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import { SetPublishDate } from "./PublishModal/SetPublishDateModal";
import { ICompany } from "../../../../../../interface/services";
import { usePublishOrg } from "../../../../../../hooks/usePublishOrg";
import { AedasLoading } from "../../../../../Shared/AedasLoading/AedasLoading";
import { CustomModal } from "../../../../../Shared/Modals/ModalCustom/CustomModal";
import { useState } from "react";

interface Props {
  company: ICompany | null;
  date: string;
  id_mongo: string;
}

export const PublishOrg = (props: Props) => {
  const [loadingPublish, setLoadingPublish] = useState(false);
  const {
    schedulePublish,
    handlePublish,
    handleRemoveScheduledPublish,
    handleSchedulePublish,
    isLoading,
    setIsLoading,
    openModalShow,
    setOpenModalShow,
  } = usePublishOrg(props.id_mongo);
  const [disable, setDisable] = useState(false);
  if (isLoading || loadingPublish) {
    return <AedasLoading></AedasLoading>;
  }
  return (
    <div>
      <Box
        sx={{
          width: "60%",
          mx: "auto",
          my: 4,
          p: 4,
          backgroundColor: "white",
        }}
        className="text-center"
      >
        <h1>Publicar Borrador</h1>

        {schedulePublish ? (
          <p>
            Fecha de publicación Programada:{" "}
            {moment(schedulePublish.pub_date).format("DD-MM-YYYY HH:mm")}
          </p>
        ) : (
          <></>
        )}

        {schedulePublish ? (
          <Button
            className="save_button m-4"
            color="primary"
            onClick={handleRemoveScheduledPublish}
          >
            Quitar Programación
          </Button>
        ) : (
          <Button
            className="save_button m-4 tbr"
            color="primary"
            onClick={handleSchedulePublish}
          >
            Programar publicación
          </Button>
        )}

        <Button
          className="save_button m-4"
          color="primary"
          disabled={disable}
          onClick={() => {
            handlePublish(props.company?.id as number);
            setLoadingPublish(true);
          }}
        >
          Publicar
        </Button>
      </Box>

      <CustomModal
        size="xl"
        show={openModalShow}
        onChange={() => setOpenModalShow(!openModalShow)}
        title="Programar publicación"
        body={
          <SetPublishDate
            company={props.company}
            id_mongo={props.id_mongo}
            show={() => setOpenModalShow(!openModalShow)}
            isLoading={() => setIsLoading(!isLoading)}
          />
        }
      ></CustomModal>
    </div>
  );
};
