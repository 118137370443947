import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import { AedasLoading } from "../../../Shared/AedasLoading/AedasLoading";
import { Button, Form } from "react-bootstrap";
import { Formik } from "formik";
import {
  createInternalPosition,
  updateInternalPosition,
} from "../../../../Services/InternalPosition";
import { useState } from "react";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import {
  addPosition,
  editPosition,
} from "../../../../Redux/Actions/Positions.action";
import DeleteIcon from "@mui/icons-material/Delete";
import ModeEditIcon from "@mui/icons-material/ModeEdit";

interface Props {
  positionsAll: any;
  setShowNewPositionForm: any;
  showNewPositionForm: any;
  initialValues: any;
  sortedPositions: any;
  setSelectedPosition: any;
  setOnlySee: any;
  setShowModal: any;
}

export const Positions = ({
  positionsAll,
  setShowNewPositionForm,
  showNewPositionForm,
  initialValues,
  sortedPositions,
  setSelectedPosition,
  setOnlySee,
  setShowModal,
}: Props) => {
  const dispatch = useDispatch();
  const [editingPositionId, setEditingPositionId] = useState<number | null>(
    null
  );

  const handleSubmitNewPosition = (values: any) => {
    createInternalPosition(values).then((response: any) => {
      toast.success("Nuevo puesto añadido correctamente");
      setShowNewPositionForm(false);
      dispatch(addPosition(response));
    });
  };

  const handleSubmitEditPosition = (values: any) => {
    if (editingPositionId !== null) {
      updateInternalPosition(values).then((response: any) => {
        toast.success("Puesto actualizado correctamente");
        setEditingPositionId(null);
        dispatch(editPosition(response));
      });
    }
  };

  const handleEditPositionClick = (positionId: number) => {
    setEditingPositionId(positionId);
  };

  return (
    <>
      {!positionsAll ? (
        <AedasLoading />
      ) : (
        <div className="tab_colum">
          <span className="colum_title">Roles aplicaciones</span>
          <div className="positions-tabs">
            <Button
              className="save_button w-15"
              onClick={() => setShowNewPositionForm(true)}
              disabled={showNewPositionForm}
            >
              Añadir puesto
            </Button>

            <div className="positions-container">
              {showNewPositionForm && (
                <Formik
                  initialValues={initialValues}
                  onSubmit={handleSubmitNewPosition}
                >
                  {({ values, handleChange, handleSubmit }) => (
                    <Form
                      className="position new-position"
                      onSubmit={handleSubmit}
                    >
                      <Form.Control
                        className="new-position-input"
                        type="text"
                        placeholder="Nombre del nuevo puesto"
                        name="name"
                        value={values.name}
                        onChange={handleChange}
                      />
                      <Button className="save_button_two" type="submit">
                        Guardar
                      </Button>
                    </Form>
                  )}
                </Formik>
              )}
              {sortedPositions?.map((post: any) => {
                return (
                  <div key={post.id} className="position_icons">
                    {editingPositionId === post.id ? (
                      <Formik
                        initialValues={{ id: post.id, name: post.name }}
                        onSubmit={handleSubmitEditPosition}
                      >
                        {({ values, handleChange, handleSubmit }) => (
                          <Form
                            className="position edit-position"
                            onSubmit={handleSubmit}
                          >
                            <Form.Control
                              className="edit-position-input"
                              type="text"
                              name="name"
                              value={values.name}
                              onChange={handleChange}
                            />
                            <input type="hidden" name="id" value={values.id} />
                            <Button className="save_button_two" type="submit">
                              Guardar
                            </Button>
                          </Form>
                        )}
                      </Formik>
                    ) : (
                      <div className="position">{post.name}</div>
                    )}
                    <div className="icons_container">
                      <RemoveRedEyeIcon
                        className="icon"
                        onClick={() => {
                          setSelectedPosition(post);
                          setShowModal(true);
                          setOnlySee(true);
                        }}
                      ></RemoveRedEyeIcon>
                      <DeleteIcon
                        onClick={() => {
                          setSelectedPosition(post);
                          setShowModal(true);
                          setOnlySee(false);
                        }}
                        className="icon"
                      ></DeleteIcon>
                      <ModeEditIcon
                        onClick={() => handleEditPositionClick(post.id)}
                        className="icon"
                      ></ModeEditIcon>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      )}
    </>
  );
};
