import { Autocomplete, TextField } from "@mui/material";
import { useEffect, useState } from "react";
import { useFormik } from "formik";
import {
  ICompany,
  IDepartments,
  IEmployeeInsightsHub,
  ITags,
} from "../../../interface/services";
import { Button } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import { getEmployeeInsightsHub } from "../../../Services/Visual/visual";
import { toast } from "react-toastify";
interface FormValues {
  company: ICompany | null;
  tag: ITags | null;
  department: IDepartments | null;
}

export const CustomBoxModal = ({ employee }: { employee: any }) => {
  const [employeeInsightsHub, setEmployeeInsightsHub] =
    useState<IEmployeeInsightsHub>({
      Departments: [],
      Companies: [],
      Tags: [],
    });
  const [employeeInsightsHubFull, setEmployeeInsightsHubFull] = useState<any>(
    []
  );
  const history = useHistory();

  useEffect(() => {
    getEmployeeInsightsHub(employee.id).then((data) => {
      if (data !== undefined) {
        setEmployeeInsightsHub(data);
        const combinedArray: any = [...data.Departments, ...data.Tags].map(
          (item) => {
            if (item.name) {
              item.name = "AEDAS - " + item.name;
            }
            // Agregar la propiedad 'type' para distinguir entre Tags y Departments
            item.type = data.Departments.includes(item) ? "Department" : "Tag";
            return item;
          }
        );

        setEmployeeInsightsHubFull(combinedArray);
      }
    });
  }, []);

  const { setFieldValue, values, handleSubmit } = useFormik<FormValues>({
    initialValues: {
      company: null,
      tag: null,
      department: null,
    },
    onSubmit: (values) => {
      // si es AEDA
      if (values.company?.id === 1) {
        if (values.department == null) {
          // DEBE SELECCIONAR SI O SI UN DEPARMENT
          toast.error("Debe seleccionar un departamento");
        } else {
          history.push(
            `/organigrama/departamento/${values.department.id}/${employee.id}`
          );
        }
      } else if (values.tag !== null) {
        if (values.tag.type === "Department") {
          history.push(
            `/organigrama/departamento/${values.tag.id}/${employee.id}`
          );
        } else {
          history.push(`/organigrama/etiqueta/${values.tag.id}/${employee.id}`);
        }
      } else {
        if (values.company?.id !== undefined) {
          history.push(
            `/organigrama/company/${values.company?.id}/employee/${employee.id}`
          );
        } else {
          toast.error("Debe seleccionar una opción");
        }
      }
    },
  });

  useEffect(() => {
    if (values.company?.id === 1) {
      if (employeeInsightsHub.Departments.length > 0) {
        setFieldValue("department", employeeInsightsHub.Departments[0]);
      }
    }
  }, [values.company?.id, employeeInsightsHub.Departments, setFieldValue]);

  return (
    <>
      <form onSubmit={(values) => handleSubmit(values)} className="boxModal">
        {employeeInsightsHub.Companies.length > 1 && (
          <Autocomplete
            id="company"
            options={employeeInsightsHub.Companies}
            getOptionLabel={(option: ICompany) => option.name}
            value={values.company}
            onChange={(e, value) => setFieldValue("company", value)}
            renderInput={(params) => (
              <TextField {...params} label="Compañia" size="medium" />
            )}
          />
        )}
        {/* {values.tag === null && values.company?.id === 1 && (
          <Autocomplete
            style={{}}
            id="department"
            options={employeeInsightsHub.Departments}
            getOptionLabel={(option: IDepartments) => option.name}
            value={
              values.department ||
              (employeeInsightsHub.Departments.length > 0
                ? employeeInsightsHub.Departments[0]
                : null)
            }
            onChange={(e, value) => setFieldValue("department", value)}
            renderInput={(params) => (
              <TextField {...params} label="Departamento" size="medium" />
            )}
          />
        )} */}
        {employeeInsightsHub.Companies.length > 0 &&
          employeeInsightsHub.Departments.length > 0 &&
          employeeInsightsHub.Tags.length > 0 &&
          values.company === null && (
            <Autocomplete
              id="tag"
              options={employeeInsightsHubFull}
              getOptionLabel={(option: ITags) => option.name}
              value={values.tag}
              onChange={(e, value) => setFieldValue("tag", value)}
              renderInput={(params) => (
                <TextField {...params} label="Organigrama" size="medium" />
              )}
            />
          )}

        <Button type="submit" className="save_button" color="primary">
          Buscar
        </Button>
      </form>
    </>
  );
};
