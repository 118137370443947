import { useEffect, useState } from "react";



/**
 * CustomHook que arregla el error de consola. 'Warning: Can't perform a React state update on an unmounted component.', detiene las funciones asyncronas de gestion de estado segun se desmonta el componente y evita leaks de memoria
 * @returns 
 */
export function useIsMounted () {
    const [isMounted, setIsMounted] = useState(false)

    useEffect(() => {
        setIsMounted(true)
      return () => {
        setIsMounted(false)
      }
    }, [])

    return isMounted
    
}