import {
  Autocomplete,
  FormControlLabel,
  FormGroup,
  Switch,
  TextField,
} from "@mui/material";
import { useFormik } from "formik";
import { useEditEmployee } from "../../../../hooks/modals/useEditEmployee";
import { Button } from "react-bootstrap";
import { ICompany, IDelegation } from "../../../../interface/services";
import "./editEmployee.css";
import { TagRow } from "./TagRow";
import { useEffect } from "react";
import { useSelector } from "react-redux";

export const EditEmployee = (props: any) => {
  const { employee, departaments, delegations, companies, tags, employees } =
    props;
  const { handleSubmit, handleDuplicateEmployee, giveDefaultValues } =
    useEditEmployee(props);
  const defaultValues = giveDefaultValues(employee);
  const positionsAll = useSelector(
    (state: any) => state.positionsReducer.positions
  );
  const locationsAll = useSelector(
    (state: any) => state.locationsReducer.locations
  );

  const formik = useFormik({
    initialValues: {
      company: defaultValues.company ?? "",
      delegation:
        defaultValues.delegation === undefined
          ? "Sin Delegacion"
          : defaultValues.delegation,
      id_internal_position: employee.id_internal_position || null,
      id_location: employee.id_location || null,
      in_comite_inversiones: employee.in_comite_inversiones ?? false,
      in_comite_negocio: employee.in_comite_negocio ?? false,
      in_comite_cumplimiento: employee.in_comite_cumplimiento ?? false,
      in_comite_esg: employee.in_comite_esg ?? false,

      tags: [
        {
          id_employee: employee.id,
          id_tag: null,
          id_manager: null,
          altura: null,
          assistant: false,
        },
      ],
    },

    onSubmit: (values) => {
      handleSubmit(values);
    },
  });

  const handleAddTag = () => {
    const newTag = [
      ...formik.values.tags,
      {
        id_employee: employee.id,
        id_tag: null,
        id_manager: null,
        altura: null,
      },
    ];
    formik.setFieldValue("tags", newTag);
  };

  return (
    <form
      onSubmit={(values) => formik.handleSubmit(values)}
      style={{ margin: "0 auto", width: "90%" }}
    >
      <div style={{ display: "flex", justifyContent: "center", gap: "20px" }}>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            flexDirection: "column",
            width: "100%",
            gap: "20px",
          }}
        >
          <TextField
            id='name'
            label='Nombre'
            variant='filled'
            disabled
            defaultValue={employee.name}
          />
          <TextField
            id='surname'
            label='Apellidos'
            variant='filled'
            disabled
            defaultValue={employee.surname}
          />
          <TextField
            id='visiblename'
            label='Nombre Visible'
            variant='filled'
            disabled
            defaultValue={employee.display_name}
          />
          <TextField
            id='email'
            label='Email'
            variant='filled'
            disabled
            defaultValue={employee.email}
          />
          <TextField
            id='departament'
            label='Departamento'
            variant='filled'
            disabled
            defaultValue={
              departaments.find((d: any) => d.id === employee.id_department)
                ?.name
            }
          />
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            flexDirection: "column",
            width: "100%",
            gap: "20px",
          }}
        >
          <TextField
            id='position'
            label='Cargo'
            variant='filled'
            disabled
            defaultValue={employee.azure_rol}
          />
          <Autocomplete
            id='internal_position'
            options={positionsAll}
            value={positionsAll?.find(
              (p: any) => p.id == formik.values.id_internal_position
            )}
            getOptionLabel={(positions) => positions.name}
            onChange={(e, value) => {
              formik.setFieldValue("id_internal_position", value?.id);
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                required
                label='Puesto Interno'
                size='medium'
              />
            )}
          />
          <Autocomplete
            id='id_location'
            options={locationsAll}
            value={locationsAll?.find(
              (p: any) => p.id == formik.values.id_location
            )}
            getOptionLabel={(positions) => positions.name}
            onChange={(e, value) => {
              formik.setFieldValue("id_location", value?.id);
            }}
            renderInput={(params) => (
              <TextField {...params} required label='Ubicación' size='medium' />
            )}
          />
          <Autocomplete
            id='company'
            options={companies}
            value={formik.values.company}
            getOptionLabel={(company: ICompany) => company.name}
            onChange={(e, value) => {
              formik.setFieldValue("company", value);
            }}
            renderInput={(params) => (
              <TextField {...params} label='Empresa' size='medium' />
            )}
          />
          <Autocomplete
            id='delegation'
            options={delegations}
            value={formik.values.delegation}
            getOptionLabel={(delegation: IDelegation) =>
              delegation.name ?? "Sin Delegacion"
            }
            onChange={(e, value) => {
              formik.setFieldValue("delegation", value);
            }}
            renderInput={(params) => (
              <TextField {...params} label='Delegación' size='medium' />
            )}
          />
        </div>
      </div>
      <FormGroup className='d-flex my-4' style={{ flexDirection: "row" }}>
        <FormControlLabel
          checked={formik.values.in_comite_inversiones}
          control={<Switch />}
          label='Comité de Inversiones'
          onChange={(e, value) => {
            formik.setFieldValue("in_comite_inversiones", value);
          }}
        />
        <FormControlLabel
          checked={formik.values.in_comite_negocio}
          aria-required
          control={<Switch />}
          label='Comité de Negocio'
          onChange={(e, value) => {
            formik.setFieldValue("in_comite_negocio", value);
          }}
        />
        <FormControlLabel
          checked={formik.values.in_comite_cumplimiento}
          control={<Switch />}
          label='Comité de Cumplimiento'
          onChange={(e, value) => {
            formik.setFieldValue("in_comite_cumplimiento", value);
          }}
        />
        <FormControlLabel
          checked={formik.values.in_comite_esg}
          control={<Switch />}
          label='Comité de ESG'
          onChange={(e, value) => {
            formik.setFieldValue("in_comite_esg", value);
          }}
        />
      </FormGroup>
      {formik.values.tags.map((item: any, index: number) => {
        return (
          <TagRow
            formik={formik}
            key={index}
            index={index}
            tags={tags}
            employees={employees}
          ></TagRow>
        );
      })}

      <div className='button-container d-flex justify-content-center'>
        <Button
          className='save_button text-center mx-4 w-25 '
          color='primary'
          onClick={handleDuplicateEmployee}
        >
          Duplicar empleado
        </Button>
        <Button
          className='save_button text-center mx-4 w-25  '
          color='primary'
          onClick={handleAddTag}
        >
          Añadir Etiqueta
        </Button>
        <Button
          className='save_button text-center mx-4 w-25 d-flex justify-content-center'
          color='primary'
          type='submit'
        >
          Guardar
        </Button>
      </div>
    </form>
  );
};
