import React, { useState, useEffect } from "react";
import { Button, IconButton, TextField, Typography } from "@mui/material";
import Drawer from "@mui/material/Drawer";
import CloseIcon from "@mui/icons-material/Close";
import EmailIcon from "@mui/icons-material/Email";
import "./Drawer.css";
import { useHistory } from "react-router-dom";
import { getAllTagsEmployee } from "../../../Services/TagServices";
import { useSelector } from "react-redux";
import { validatePermission } from "../../../Utils/permissionsUtils";
import {
  getAllEmployeeTags,
  updateEmployeeTag,
} from "../../../Services/EmployeeTagService";
import { getAllInternalPositions } from "../../../Services/InternalPosition";

type Anchor = "top" | "left" | "bottom" | "right";

export function TemporaryDrawer(props: any) {
  const { open, setOpen, data, allData } = props;
  const [order, setOrder] = useState(0);
  const [openTooltip, setOpenTooltip] = useState(false);
  const history = useHistory();
  const [tasgEmployee, setTasgEmployee] = useState<any>([]);
  const user = useSelector((state: any) => state.userReducer.user);
  const positionsAll = useSelector(
    (state: any) => state.positionsReducer.positions
  );
  const handleTeams = (email: any) => {
    window.open(
      "https://teams.microsoft.com/l/chat/0/0?users=" + email,
      "_blank",
      "noopener,noreferrer"
    );
  };
  const handleCallTeams = (email: any) => {
    window.open(
      "https://teams.microsoft.com/l/call/0/0?users=" + email,
      "_blank",
      "noopener,noreferrer"
    );
  };

  const handleEmail = (email: any) => {
    window.open("mailto:" + email, "_blank", "noopener,noreferrer");
  };

  const toggleDrawer =
    (anchor: Anchor, openValue: boolean) =>
    (event: KeyboardEvent | MouseEvent) => {
      if (
        event.type === "keydown" &&
        ((event as KeyboardEvent).key === "Tab" ||
          (event as KeyboardEvent).key === "Shift")
      ) {
        return;
      }

      setOpen(openValue);
    };

  useEffect(() => {
    if (openTooltip) {
      setTimeout(() => {
        setOpenTooltip(false);
      }, 1000);
    }
  }, [openTooltip]);

  const checkAccessAsignOrder = () => {
    return (
      validatePermission(
        user ? user.permissions : [],
        "app_organigrama.rrcc.all"
      ) && window.location.pathname.includes("etiqueta")
    );
  };

  useEffect(() => {
    if (data && data.id) {
      getAllTagsEmployee(data.id).then((tags) => {
        setTasgEmployee(tags);
        setOrder(structuredClone(data).order ?? 0);
      });
    }
  }, [data]);

  const checkNullOrFalse = (item: any) => {
    return item !== null && item !== undefined && item;
  };

  const asignOrder = () => {
    getAllEmployeeTags().then((responseEmployeeTags: any) => {
      if (window.location.pathname.includes("etiqueta")) {
        const TagsEmployeeSelected = responseEmployeeTags.find(
          (tagsEmployee: any) =>
            tagsEmployee.id_employee === data.id &&
            data.id_tag === tagsEmployee.id_tag &&
            data.pid === tagsEmployee.id_manager
        );
        TagsEmployeeSelected.order = order;
        updateEmployeeTag(TagsEmployeeSelected, TagsEmployeeSelected.id);
        setTimeout(() => {
          window.location.reload();
        }, 1000);
      }
    });
  };

  return (
    <React.Fragment key={"right"}>
      <Drawer
        anchor={"right"}
        open={open}
        onClose={toggleDrawer("right", false)}
      >
        <div
          className="row"
          style={{ width: "300px", height: "100vh", overflowY: "scroll" }}
        >
          <div
            className="col-12 header_drawer"
            style={{ width: "300px", height: "300px" }}
          >
            <IconButton
              onClick={() => setOpen(false)}
              style={{
                position: "absolute",
                top: "3px",
                left: "5px",
                backgroundColor: "rgba(255, 255, 255, 0.75)",
                border: "2px solid #fff",
              }}
            >
              <CloseIcon></CloseIcon>
            </IconButton>

            {!data?.img?.includes("blank") ? (
              <img
                alt=""
                className="img_drawer"
                src={process.env.REACT_APP_API + "/" + data?.img}
              ></img>
            ) : (
              <img
                alt=""
                className="img_drawer"
                src={"/Assets/img/blank.png"}
              ></img>
            )}
          </div>
          <div className="col-12" style={{ marginTop: "20px" }}>
            <h1 className="drawer_name">{data?.name}</h1>
            <h2 className="drawer_job">{data?.azure_rol}</h2>
            <div
              className="icons"
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "center",
                gap: "10px",
              }}
            >
              <IconButton
                onClick={() => handleTeams(data?.email)}
                style={{ background: "var(--blue-lis)", width: "40px" }}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="white"
                  width={"20px"}
                  className="bi bi-microsoft-teams"
                  viewBox="0 0 16 16"
                >
                  <path d="M9.186 4.797a2.42 2.42 0 1 0-2.86-2.448h1.178c.929 0 1.682.753 1.682 1.682v.766Zm-4.295 7.738h2.613c.929 0 1.682-.753 1.682-1.682V5.58h2.783a.7.7 0 0 1 .682.716v4.294a4.197 4.197 0 0 1-4.093 4.293c-1.618-.04-3-.99-3.667-2.35Zm10.737-9.372a1.674 1.674 0 1 1-3.349 0 1.674 1.674 0 0 1 3.349 0Zm-2.238 9.488c-.04 0-.08 0-.12-.002a5.19 5.19 0 0 0 .381-2.07V6.306a1.692 1.692 0 0 0-.15-.725h1.792c.39 0 .707.317.707.707v3.765a2.598 2.598 0 0 1-2.598 2.598h-.013Z" />
                  <path d="M.682 3.349h6.822c.377 0 .682.305.682.682v6.822a.682.682 0 0 1-.682.682H.682A.682.682 0 0 1 0 10.853V4.03c0-.377.305-.682.682-.682Zm5.206 2.596v-.72h-3.59v.72h1.357V9.66h.87V5.945h1.363Z" />
                </svg>
              </IconButton>
              <IconButton
                style={{ background: "var(--blue-lis)" }}
                onClick={() => handleEmail(data?.email)}
              >
                <EmailIcon style={{ fill: "white" }}></EmailIcon>
              </IconButton>
            </div>

            <div style={{ paddingLeft: "10px", paddingRight: "10px" }}>
              <hr></hr>
            </div>
          </div>
          <div style={{ marginLeft: "10px", width: "calc(100% - 10px)" }}>
            <div className="container_tags">
              <span className="info_title_drawer">Empresa</span>
              <span
                onClick={() => {
                  history.push(`/organigrama/${data.id_company}`);
                }}
                className="info_body_drawer"
              >
                {data?.name_company}
              </span>
            </div>
            <div className="container_tags">
              <span className="info_title_drawer">Departamento</span>

              {tasgEmployee.map((option: any) => (
                <span
                  onClick={() => {
                    history.push(`/organigrama/etiqueta/${option.id}`);
                  }}
                  className="info_body_drawer"
                >
                  {option?.name}
                </span>
              ))}
              <span
                onClick={() => {
                  history.push(
                    `/organigrama/departamento/${data.id_department}/${data.id}`
                  );
                }}
                className="info_body_drawer"
              >
                {data?.name_department}
              </span>
            </div>
            {data && data?.title ? (
              <div className="container_tags">
                <span className="info_title_drawer">Puesto</span>
                <span className="info_body_drawer">{data.title}</span>
              </div>
            ) : (
              <></>
            )}
            {data?.tlf && (
              <div className="container_tags">
                <span className="info_title_drawer">Teléfono</span>
                <span
                  className="info_body_drawer"
                  onClick={() => handleCallTeams(data?.email)}
                >
                  {data?.tlf}
                </span>
              </div>
            )}

            {(checkNullOrFalse(data?.in_comite_inversiones) ||
              checkNullOrFalse(data?.in_comite_negocio) ||
              checkNullOrFalse(data?.in_comite_cumplimiento) ||
              checkNullOrFalse(data?.in_comite_esg)) && (
              <div className="container_tags">
                <span className="info_title_drawer">Comité/s</span>
                <span className="info_body_drawer_comite">
                  {data?.in_comite_inversiones ? "Comité de inversiones" : ""}
                </span>
                <span className="info_body_drawer_comite">
                  {data?.in_comite_negocio ? "Comité de negocio" : ""}
                </span>
                <span className="info_body_drawer_comite">
                  {data?.in_comite_cumplimiento ? "Comité de cumplimiento" : ""}
                </span>

                <span className="info_body_drawer_comite">
                  {data?.in_comite_esg ? "Comité de ESG" : ""}
                </span>
              </div>
            )}

            {data?.extension && (
              <div className="container_tags">
                <span className="info_title_drawer">Extensión</span>
                <span
                  className="info_body_drawer"
                  onClick={() => handleCallTeams(data?.email)}
                >
                  {data?.extension}
                </span>
              </div>
            )}
            <div className="container_tags">
              <span className="info_title_drawer">Email</span>
              <div>
                <span
                  className="info_body_drawer"
                  onClick={() => handleEmail(data?.email)}
                >
                  {data?.email}
                </span>
              </div>
            </div>
            {checkAccessAsignOrder() && (
              <div className="d-flex mb-2">
                {data && (
                  <div className="d-flex flex-column">
                    <Typography>Orden en su fila</Typography>
                    <TextField
                      variant="filled"
                      type="number"
                      value={order}
                      onChange={(e: any) => {
                        setOrder(e.target.value);
                      }}
                    />
                    <Button
                      title="Asignar orden"
                      color="primary"
                      variant="outlined"
                      className="org_Button my-2"
                      onClick={() => {
                        asignOrder();
                      }}
                    >
                      Guardar
                    </Button>
                  </div>
                )}
              </div>
            )}
          </div>
        </div>
      </Drawer>
    </React.Fragment>
  );
}
