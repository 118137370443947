import axios from "axios";
import { IDelegation } from "../interface/services";
import { configAuth } from "../Utils/headersConfig";

export const getAllDelegations = () => {
  return axios
    .get(
      process.env.REACT_APP_API_BACKEND + "/api/v1" + "/delegacion/",
      configAuth()
    )
    .then((res) => res.data);
};

export const updateOrCreateDelegations = (delegation: Partial<IDelegation>) => {
  return axios.post(
    process.env.REACT_APP_API_BACKEND + "/api/v1" + "/delegacion/",
    delegation,
    configAuth()
  );
};

export const deleteDelegation = (id_delegation: number) => {
  return axios.delete(
    process.env.REACT_APP_API_BACKEND +
      "/api/v1" +
      "/delegacion/" +
      id_delegation,
    configAuth()
  );
};
