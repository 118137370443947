import { useEffect, useRef, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import moment from "moment";
import { IconButton } from "@mui/material";
import FilterListIcon from "@mui/icons-material/FilterList";
import domtoimage from "dom-to-image-more";
import { useOrganizationChart } from "../../../hooks/useOrganizationChart";
import { FilterForm } from "./FilterForm/FilterForm";
import { getSortedChildren } from "../../../Utils/orderChilds";
import { useToggle } from "../../../hooks/useToggle";
import { Leyend } from "../../../Visual/components/shared/Leyend";
import { findPeopleInDt } from "../../../Utils/findInDt";
import "./ShowOrganizationChart.css";
import NewOrg from "../../../Visual/components/org/NewOrg";
import { AedasLoading } from "../AedasLoading/AedasLoading";

export function ShowOrganizationChart({
  orgChart,
  setOrgChart,
  filter = true,
}) {
  const orgRef = useRef();
  const params = useParams();
  const location = useLocation();
  const [leyend, setShowLeyend] = useState(false);
  const [load, setIsLoading] = useState(true);
  const [filtered, setFiltered] = useState(false);
  const [showFilter, setShowFilter] = useToggle(false);
  const [departmentFiltered, setDepartmentFiltered] = useState("");
  const { departments, company, tags, isLoading, drafts } =
    useOrganizationChart(params, load);

  const version = params.version
    ? params.version
    : drafts?.find((d) => d.mongo_id === params.template_id)?.version;
  const orgType = !version
    ? "current"
    : params.version
    ? "historical"
    : "draft";

  const exportTo = () => {
    const saveAs = (uri, filename) => {
      const link = document.createElement("a");
      if (typeof link.download === "string") {
        link.href = uri;
        link.download = filename;
        //Firefox requires the link to be in the body
        document.body.appendChild(link);
        //simulate click
        link.click();
        //remove the link when done
        document.body.removeChild(link);
      } else {
        window.open(uri);
      }
    };
    const scale = filtered ? 6 : 1;
    const node = orgRef.current;
    //Parametros que pide la libreria para configurar
    const style = {
      transform: "scale(" + scale + ")",
      transformOrigin: "top left",
      width: node.offsetWidth + "px",
      height: node.offsetHeight + "px",
    };

    const param = {
      cacheBust: true,
      height: node.offsetHeight * scale,
      width: node.offsetWidth * scale,
      quality: 1,
      style,
    };
    const date = moment();
    //Guarda el organigrama dependiendo si esta filtrado como png y sino como svg
    if (filtered) {
      domtoimage
        .toPng(node, param)
        //dataUrl interno de la libreria, informacion del canvas
        .then(function (dataUrl) {
          saveAs(
            dataUrl,
            `Organigrama-${departmentFiltered ?? company?.name}-${date}.png`
          );
        })
        .catch(function (error) {
          console.error("Algo ha salido mal", error);
        });
    } else {
      domtoimage
        .toSvg(node, { cacheBust: true })
        .then(function (dataUrl) {
          saveAs(dataUrl, `Organigrama-${company?.name}-${date}.svg`);
        })
        .catch(function (error) {
          console.error("Algo ha salido mal", error);
        });
    }
  };

  useEffect(() => {
    if (orgChart) getSortedChildren(orgChart);
    setShowLeyend(findPeopleInDt(orgChart));
  }, [orgChart]);

  if (isLoading) {
    return <AedasLoading></AedasLoading>;
  }
  return (
    <>
      {orgChart && (
        <>
          {/* HEADER + FILTROS*/}
          {location.pathname.includes("/borradores") ? (
            <></>
          ) : (
            <>
              <h1 className="orgChart__title">{company.name}</h1>
              {departmentFiltered && (
                <h1 className="orgChart__title">{departmentFiltered}</h1>
              )}
            </>
          )}
          <div
            className={
              leyend ? "d-flex justify-content-between px-4 max-width" : ""
            }
          >
            {/* BOTON DE DOWNLOAD */}
            {leyend && <Leyend></Leyend>}
            <div className="d-flex justify-content-end px-4 max-width gx-4">
              {/* BOTON DE FILTRADO */}
              {filter && (
                <IconButton className="orgChart__icon" onClick={setShowFilter}>
                  <FilterListIcon />
                </IconButton>
              )}
            </div>
          </div>
          {/* MODAL DE FILTRADO DEL ORGANIGRAMA */}
          {showFilter && (
            <div className="dropdown_filter">
              <FilterForm
                company_id={params.company_id}
                departments={departments}
                tags={tags}
                type={orgType}
                isLoading={isLoading}
                setIsLoading={setIsLoading}
                setDraft={setOrgChart}
                setFiltered={setFiltered}
                setShowFilter={setShowFilter}
                setDepartmentFiltered={setDepartmentFiltered}
              />
            </div>
          )}
          {/* COMPONENTE QUE SE PINTA SI SE FILTRA POR TAGS, AL TRAER UN ARRAY DE OBJETOS EN VEZ DEL ORGANIGRAMA EN OBJETO */}
          {/* ORGANIGRAMA GENERAL QUE RECIBE UN ORGANIGRAMA COMPLETO EN SOLO UN OBJETO. */}

          <>
            <div>
              <NewOrg data={orgChart} isDraft={true}></NewOrg>
            </div>
          </>
        </>
      )}
    </>
  );
}
