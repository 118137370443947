import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { useHistory } from "react-router-dom";
import { AedasLoading } from "../../Shared/AedasLoading/AedasLoading";
import { DataGridEditabled } from "../../Shared/DataGridEditabled/DataGridEditabled";
import { getAllCompany } from "../../../Services/CompanyService";
import { ICompany } from "../../../interface/services";
import { TableOrgChart } from "./TableOrgChart";
import { useSelector } from "react-redux";
import "./OrganizationChart.css";

export const OrganizationChart = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [companys, setCompanys] = useState<ICompany[]>([]);
  const history = useHistory();
  const tableOrgChart = new TableOrgChart(history);
  const finishLogin = useSelector(
    (state: any) => state?.userReducer?.finishLogin
  );

  useEffect(() => {
    finishLogin &&
      getAllCompany()
        .then((response: ICompany[]) => {
          setCompanys(response);
          setIsLoading(false);
        })
        .catch((error) => {
          if (error.status === 404) setIsLoading(false);
          else toast.error("Ha habido un error");
        });
  }, [finishLogin]);

  return (
    <>
      {isLoading ? (
        <AedasLoading></AedasLoading>
      ) : (
        <>
          <h1 className="main-title">Organigramas</h1>
          <div className=" m-4" style={{ height: "calc(100vh - 15%)" }}>
            <DataGridEditabled
              onlyEdit={false}
              showHeader={false}
              rows={companys}
              openModalOnClickCell={() => null}
              columns={tableOrgChart.columns}
              title=""
            ></DataGridEditabled>
          </div>
        </>
      )}
    </>
  );
};
