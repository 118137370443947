export const getLocations = (
    locations: any
  ) => {
    return {
      type: "@locations/all",
      payload: { locations: locations },
    };
  };
  
  export const addLocation = (
    location:any
  ) => {
      return {
          type: "@locations/add",
          payload: { newLocation: location },
        };
  }
  
  export const editLocation = (location:any) => {    
    return {
      type: "@locations/edit",
      payload: { updatedLocation: location },
    };
  }
  
  export const deleteLocation = (location: any) => {
    return {
      type: "@locations/delete",
      payload: { deletedLocation: location},
    };
  }