import { useEffect, useState } from "react";
import { getCompanyByID } from "../../Services/CompanyService";
import { getOrganigrama } from "../../Services/EmployeeConfig";
import { getSortedChildren } from "../../Utils/orderChilds";
import { searchTree } from "../utils/SearchTree";


export function useOrgCompany(params: any) {
  const [organigrama, setOrganigrama] = useState([]);
  const [company, setCompany] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(false);
  useEffect(() => {
    if (isLoading) {
      let aux = [];
      aux.push(
        getOrganigrama(params.id)
          .then((res) => {
            if (params.employee !== null && params.employee !== undefined) {
              processData(res);
            } else {
              setOrganigrama(res)
              setIsLoading(false);
            }
          })
          .catch((error) => {
            setError(true)
          })
      );
      aux.push(getCompanyByID(params.id).then(setCompany));
      Promise.all(aux).finally(() => setIsLoading(false));
    }
  }, [isLoading]);

  useEffect(() => {
    if (organigrama) getSortedChildren(organigrama);
  }, []);


  const processData = (info: any) => {
    searchTree(info, params.employee);
    setOrganigrama(info);
    setIsLoading(false);
  };

  return {
    organigrama,
    company,
    isLoading,
    error,
  };
}
