import axios from "axios";
import { toast } from "react-toastify";
import { configAuth } from "../Utils/headersConfig";

export const getAllOrders = () => {
  return axios
    .get(
      process.env.REACT_APP_API_BACKEND + "/api/v1" + "/order/",
      configAuth()
    )
    .then((res) => res.data)
    .catch((error) => toast.error("Error en la carga de datos"));
};

export const createOrder = (order: any) => {
  return axios
    .post(
      process.env.REACT_APP_API_BACKEND + "/api/v1" + "/order/",
      order,
      configAuth()
    )
    .then((res) => toast.success("Creada correctamente"))
    .catch((error) => toast.error("Error al crear el Orden"));
};

export const updateOrder = (orderID: any, orderUpdated: any) => {
  return axios
    .put(
      process.env.REACT_APP_API_BACKEND + "/api/v1" + "/order/" + orderID,
      orderUpdated,
      configAuth()
    )
    .then((res) => toast.success("Actualizado correctamente"))
    .catch((error) => toast.error("Error al actualizar los datos"));
};

export const removeOrder = (orderID: any) => {
  return axios
    .delete(
      process.env.REACT_APP_API_BACKEND + "/api/v1" + "/order/" + orderID,
      configAuth()
    )
    .then((res) => toast.success("Eliminado correctamente"))
    .catch((error) => {
      toast.error("Ha habido un problema, intentelo más tarde");
    });
};
