import { useHistory } from "react-router-dom";
import "./company.css";

export function CompanyBox(props: any) {
  const history = useHistory();
  const handleClick = () => {
    history.push("/organigrama/" + props.id);
  };

  return (
    <div onClick={handleClick} className="company_box">
      <h2 className="text_inner text-uppercase">{props.name}</h2>
    </div>
  );
}
