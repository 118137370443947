import { Tab, TabList, Tabs, TabPanel } from "react-tabs";
import { Company } from "./Company/Company";
import { Employees } from "./Employees/Employees";
import { Orders } from "./Orders/Orders";
import { Tags } from "./Tags/Tags";
import { PositionsAndLocations } from "./Positions/PositionsAndLocations";

export function EmployeeManagementMain() {
  return (
    <div className="px-4" style={{ height: " calc(100vh - 20%)" }}>
      <h1 className="main-title">Gestión de empleados</h1>
      <Tabs>
        <TabList>
          <Tab>Empleados</Tab>
          <Tab>Empresas</Tab>
          {/* <Tab>Delegaciones</Tab> */}
          <Tab>Etiquetas</Tab>
          <Tab>Orders</Tab>
          <Tab>Roles Aplicaciones</Tab>
        </TabList>

        <TabPanel>
          <Employees></Employees>
        </TabPanel>

        <TabPanel>
          <Company></Company>
        </TabPanel>

        {/* <TabPanel>
          <Delegation></Delegation>
        </TabPanel> */}

        <TabPanel>
          <Tags></Tags>
        </TabPanel>

        <TabPanel>
          <Orders></Orders>
        </TabPanel>

        <TabPanel>
          <PositionsAndLocations></PositionsAndLocations>
        </TabPanel>
      </Tabs>
    </div>
  );
}
