import { useEffect, useState } from "react";
import { getCompanyByID } from "../../Services/CompanyService";
import { getEmployeeTags } from "../../Services/Visual/visual";
import { getAllTags } from "../../Services/TagServices";
import { ITags } from "../../interface/services";
import { getSortedChildren } from "../../Utils/orderChilds";
import { searchTree } from "../utils/SearchTree";

export function useOrgTag(params: any) {
  const [employeeTags, setEmployeeTags] = useState([]);
  const [company, setCompany] = useState(null);
  const [tags, setTags] = useState<ITags[]>([]);
  const [error, setError] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  useEffect(() => {
    if (isLoading) {
      let aux = [];
      aux.push(
        getEmployeeTags(params.id)
          .then((res) => {
            if (params.employee !== null && params.employee !== undefined) {
              processData(res);
            } else {
              setEmployeeTags(res);
              setIsLoading(false);
            }
          })
          .catch((error) => {
            setError("No esta el organigrama disponible");
            setIsLoading(false);
          })
      );
      aux.push(getAllTags().then(setTags));
      aux.push(getCompanyByID("1").then(setCompany));

      Promise.all(aux)
        .then(() => setIsLoading(false))
        .catch(() => {
          setIsLoading(false);
        })
        .finally(() => setIsLoading(false));
    }
  }, [isLoading, params.id]);

  useEffect(() => {
    if (employeeTags) getSortedChildren(employeeTags);
  }, []);

  const processData = (organizationalCharts: any) => {
    let employesTag: any = [];
    organizationalCharts.forEach((charts: any) => {
      if (charts.assistant !== null || charts.assistant !== undefined) {
        if (charts?.assistant?.id.toString() === params.employee) {
          charts.assistant.selected = true;
          employesTag.push(charts);
        }
      }

      if (charts.id !== null || charts.id !== undefined) {
        if (charts?.id.toString() === params.employee) {
          charts.selected = true;
          employesTag.push(charts);
        } else if (charts.children != null && charts.children.length > 0) {
          let i;
          let result = null;
          for (i = 0; result == null && i < charts.children.length; i++) {
            result = searchTree(charts.children[i], params.employee);
          }
          employesTag.push(charts);
        }
      }
    });
    setEmployeeTags(employesTag);
    setIsLoading(false);
  };

  return {
    isLoading,
    error,
    employeeTags,
    company,
    tags,
  };
}
