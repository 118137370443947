import { useEffect, useState } from "react";
import { IconButton } from "@mui/material";
import EmailIcon from "@mui/icons-material/Email";
import AddIcon from "@mui/icons-material/Add";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import { TemporaryDrawer } from "../Drawer/Drawer";
import { CustomModal } from "../../../Components/Shared/Modals/ModalCustom/CustomModal";
import { useToggle } from "../../../hooks/useToggle";
import { CustomBoxModal } from "./CustomBoxModal";
import "./CustomBox.css";
import { useHistory } from "react-router-dom";
import { getEmployeeInsightsHub } from "../../../Services/Visual/visual";

export function CustomBox(props: any) {
  const { employee, AllData } = props;
  const [selectedEmployee, setSelectedEmployee] = useState(undefined);
  const [openModal, setOpenModal] = useToggle();
  const [open, setOpen] = useToggle(false);

  const history = useHistory();

  const handleTeams = (email: any) => {
    window.open(
      "https://teams.microsoft.com/l/chat/0/0?users=" + email,
      "_blank",
      "noopener,noreferrer"
    );
  };

  const handleEmail = (email: any) => {
    window.open("mailto:" + email, "_blank", "noopener,noreferrer");
  };

  const handleRedirectOrg = () => {
    getEmployeeInsightsHub(employee.id).then((data) => {
      if (data !== undefined) {
        if (
          data.Companies.length === 1 &&
          data.Departments.length === 1 &&
          data.Tags.length === 0
        ) {
          if (data.Companies[0].id === 1) {
            history.push(
              `/organigrama/departamento/${data.Departments[0].id}/${employee.id}`
            );
          } else {
            history.push(
              `/organigrama/company/${data.Companies[0].id}/employee/${employee.id}`
            );
          }
        } else {
          setOpenModal();
        }
      }
    });
  };

  return (
    <>
      <CustomModal
        size="xs"
        show={openModal}
        onChange={setOpenModal}
        title="Selección"
        body={<CustomBoxModal employee={selectedEmployee}></CustomBoxModal>}
      ></CustomModal>
      <TemporaryDrawer
        open={open}
        setOpen={setOpen}
        AllData={AllData}
        data={selectedEmployee ?? {}}
      ></TemporaryDrawer>
      <div
        style={{
          overflow: "hidden",
          flexDirection: "row",
        }}
        className={"search_box"}
      >
        <div style={{ width: "103px" }}>
          <img
            alt="Imagen del empleado"
            className="imgTree_search"
            src={process.env.REACT_APP_API + "/" + employee.img}
            style={{ cursor: "pointer" }}
            onClick={() => {
              setOpen();
              setSelectedEmployee(employee);
            }}
          ></img>
        </div>

        <div className="more_info_container_search">
          <div
            className="container_name_search"
            style={{ cursor: "pointer" }}
            onClick={() => {
              setOpen();
              setSelectedEmployee(employee);
            }}
          >
            <h3 className="titleName_search">{employee.name}</h3>
            <h5 className="titleName_job_search">{employee.azure_rol}</h5>
            <div></div>
          </div>

          <div className="container_action_search">
            <IconButton
              style={{ width: "30px", height: "30px" }}
              onClick={() => handleEmail(employee.email)}
            >
              <EmailIcon
                titleAccess="Enviar un correo electrónico"
                style={{ width: "18px" }}
              ></EmailIcon>
            </IconButton>
            <IconButton
              style={{ width: "30px", height: "30px" }}
              onClick={() => {
                setSelectedEmployee(employee);
                handleRedirectOrg();
              }}
            >
              <LocationOnIcon
                titleAccess="Localizar en el organigrama"
                style={{ width: "18px" }}
              ></LocationOnIcon>
            </IconButton>
            <IconButton
              style={{ padding: "4px" }}
              onClick={() => handleTeams(employee.email)}
            >
              <a title="Abrir conversación en Teams">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="#092e3b"
                  width={"18px"}
                  className="bi bi-microsoft-teams"
                  viewBox="0 0 16 16"
                >
                  <path d="M9.186 4.797a2.42 2.42 0 1 0-2.86-2.448h1.178c.929 0 1.682.753 1.682 1.682v.766Zm-4.295 7.738h2.613c.929 0 1.682-.753 1.682-1.682V5.58h2.783a.7.7 0 0 1 .682.716v4.294a4.197 4.197 0 0 1-4.093 4.293c-1.618-.04-3-.99-3.667-2.35Zm10.737-9.372a1.674 1.674 0 1 1-3.349 0 1.674 1.674 0 0 1 3.349 0Zm-2.238 9.488c-.04 0-.08 0-.12-.002a5.19 5.19 0 0 0 .381-2.07V6.306a1.692 1.692 0 0 0-.15-.725h1.792c.39 0 .707.317.707.707v3.765a2.598 2.598 0 0 1-2.598 2.598h-.013Z" />
                  <path d="M.682 3.349h6.822c.377 0 .682.305.682.682v6.822a.682.682 0 0 1-.682.682H.682A.682.682 0 0 1 0 10.853V4.03c0-.377.305-.682.682-.682Zm5.206 2.596v-.72h-3.59v.72h1.357V9.66h.87V5.945h1.363Z" />
                </svg>
              </a>
            </IconButton>
            <IconButton
              style={{ width: "30px", height: "30px" }}
              onClick={() => {
                setOpen();
                setSelectedEmployee(employee);
              }}
            >
              <AddIcon
                titleAccess="Ampliar información"
                style={{ width: "18px" }}
              ></AddIcon>
            </IconButton>
          </div>
        </div>
      </div>
    </>
  );
}

export default CustomBox;
