
const initialState = {
  positions: null,
};
export const positionsReducer = (state: any = initialState, action: any) => {
  switch (action.type) {
    case "@positions/all":
      return {
        ...state,
        positions: action.payload.positions
      };
    case "@positions/add":
        return {
            ...state,
            positions: [...state.positions,action.payload.newPosition.data]
        }
    case "@positions/edit":
      const {id, name} = action.payload.updatedPosition;
      const positions = state.positions.map((position:any) => {
        if (position.id === id) {
          return { ...position, name };
        }
        return position;
      });
      return {
        ...state,
        positions: positions
      }
    case "@positions/delete":
      const deleteId = action.payload.deletedPosition.id;
      const filteredPositions = state.positions.filter(
        (position:any) =>{
          return position.id !== deleteId
        }
      );
      ;
      
      return {
        ...state,
        positions: filteredPositions
      };
    default:
      return state;
  }
};
