import { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import { TextField, MenuItem } from "@mui/material";
import "./AddDraftModal.css";
import {
  createFromDraft,
  createFromLastPublished,
} from "../../../../../Services/OrganizationChartService";
import { AedasLoading } from "../../../../Shared/AedasLoading/AedasLoading";

export const AddDraftModal = (props: any) => {
  const { selectedCompany, onChange, onFinishChangue } = props;
  const [draftSelected, setDraftSelected] = useState<any>("");
  const [mongoIDDraft, setMongoIDDraft] = useState<any>("");
  const [isLoading, setIsLoading] = useState(false);
  const defaultCompany: any = selectedCompany ? selectedCompany.name : "";
  let history = useHistory();
  const draftsOptions: any = [
    {
      key: -1,
      value: "Desde cero",
      label: "Desde cero",
    },
  ];
  if (props.draftsOptions.length > 0) {
    draftsOptions.push({
      key: -2,
      value: "Desde último organigrama publicado",
      label: "Desde último organigrama publicado",
    });
  }
  props.draftsOptions.map((element: any, i: number) => {
    draftsOptions.push({
      key: i,
      value: element.version,
      label: element.version,
    });
  });

  useEffect(() => {
    if (mongoIDDraft !== null) {
      history.push(
        `/organigramas/borradores/${selectedCompany.id}/${mongoIDDraft}`
      );
    }
  }, [mongoIDDraft]);

  const handleSave = (e: any) => {
    setIsLoading(true);
    const form = e.target.form;
    const draftInput = form.querySelector("#draft").textContent;
    const draft = props.draftsOptions.find(
      (d: any) => d.version === draftInput
    );

    if (draftInput === "Desde cero") {
      history.push(`/organigramas/borradores/${selectedCompany.id}/-1`);
    }
    if (draftInput.includes("publicado")) {
      createFromLastPublished(selectedCompany.id).then((response: any) => {
        history.push(
          `/organigramas/borradores/${selectedCompany.id}/${response.data._id}`
        );
        setMongoIDDraft(response.data._id);
        onFinishChangue();
      });
    } else {
      createFromDraft(draft).then((resp) => {
        setIsLoading(false);
        history.push(
          `/organigramas/borradores/${selectedCompany.id}/${resp?.data}`
        );
      });
    }
  };

  const handleChange = (event: any) => {
    const newValue = event.target.value;
    setDraftSelected(newValue);
  };

  if (isLoading) {
    return <AedasLoading></AedasLoading>;
  }
  return (
    <Box
      component="form"
      sx={{
        "& .MuiTextField-root": { my: 2, mx: 4, width: "40%" },
        width: "100%",
        mx: "auto",
        my: 2,
      }}
      noValidate
      autoComplete="off"
      className="text-center"
      onSubmit={handleSave}
    >
      <TextField
        id="company"
        defaultValue={defaultCompany}
        label="Empresa"
        disabled
      />

      <TextField
        id="draft"
        defaultValue={draftsOptions[0]}
        label="Desde"
        onChange={(e) => handleChange(e)}
        select
        value={draftSelected}
      >
        {draftsOptions.map((option: any) => (
          <MenuItem key={option.value} value={option.value}>
            {option.label}
          </MenuItem>
        ))}
      </TextField>

      <Button className="save_button" color="primary" onClick={handleSave}>
        Crear borrador
      </Button>
    </Box>
  );
};
