import React from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import { TextField, Autocomplete } from "@mui/material";
import { useFormik } from "formik";
import { publishDraftDate } from "../../../../../../../Services/OrganizationChartService";

export const SetPublishDate = (props: any) => {
  const { company } = props;

  const { handleChange, handleSubmit, values, setFieldValue } = useFormik({
    initialValues: {
      date: "",
      time: "",
    },
    validationSchema: null,
    onSubmit: (values) => {
      const { date, time } = values;
      const value = date + "T" + time;
      // Los datos la api los recibe como un objeto Data Time, esto no se ha probado
      // const data = new Date(value);
      // console.log(data);
      publishDraftDate(props.id_mongo, value).then(() => {
        props.isLoading(!props.isLoading);
        props.show(!props.show);
      });
    },
  });

  const timeSlots = Array.from(new Array(24 * 2)).map(
    (_, index) =>
      `${index < 20 ? "0" : ""}${Math.floor(index / 2)}:${
        index % 2 === 0 ? "00" : "30"
      }`
  );

  return (
    <Box
      component={Box}
      sx={{
        "& .MuiTextField-root": { my: 1, mx: 3, width: "50%" },
        width: "100%",
        mx: 5,
        my: 2,
      }}
      style={{ overflow: "hidden" }}
      className="text-center"
    >
      <p>
        Programar una fecha para la publicación del organigrama de{" "}
        {company.name}
      </p>

      <div className="d-flex align-items-center justify-content-center w-100">
        <form
          onSubmit={handleSubmit}
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <input
            type="date"
            onChange={handleChange("date")}
            value={values.date as string}
            style={{
              width: "50%",
              borderRadius: "10px",
              height: "3.5rem",
              background: "transparent",
            }}
          ></input>
          <Autocomplete
            id="disabled-options-demo"
            options={timeSlots}
            value={values.time}
            onChange={(_e, value) => setFieldValue("time", value)}
            sx={{ width: 350 }}
            renderInput={(params) => <TextField {...params} label="Hora" />}
          />
          <button
            className="save_button my-1 mx-2 rounded-2"
            color="primary"
            type="submit"
          >
            Programar publicación
          </button>
        </form>
      </div>
    </Box>
  );
};
