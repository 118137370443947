import { useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { TableHistory } from "./TableHistory";
import "../OrganizationChart.css";
import { useDraftOrHistory } from "../../../../hooks/useDraftsOrHistory";
import { AedasLoading } from "../../../Shared/AedasLoading/AedasLoading";
import { DataGridEditabled } from "../../../Shared/DataGridEditabled/DataGridEditabled";

export const HistoryOrganizationChart = () => {
  const history = useHistory();
  const { company_id }: { company_id: string } = useParams();
  const [isLoading, setIsLoading] = useState(true);
  const { historicalDrafts, company } = useDraftOrHistory(
    isLoading,
    setIsLoading,
    company_id
  );
  const tableHistory = new TableHistory(company, history, setIsLoading);
  return (
    <>
      {isLoading ? (
        <AedasLoading></AedasLoading>
      ) : (
        <div className="px-4 h-100">
          <h1 className="main-title">Historial</h1>
          <DataGridEditabled
            onlyEdit={false}
            showHeader={true}
            edit={true}
            rows={historicalDrafts}
            columns={tableHistory.columns}
            title=""
            onCellClick={tableHistory.onCellClick}
            handleSave={(e: React.SyntheticEvent) => {
              setIsLoading(!isLoading);
              tableHistory.handleSave(e);
            }}
            getRowId={(row: any) => row.mongo_id}
          ></DataGridEditabled>
        </div>
      )}
    </>
  );
};
