import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./Positions.css";
import {
  getAllEmployeesWithInternalPosition,
  removeInternalPosition,
  unnasignInternalPosition,
} from "../../../../Services/InternalPosition";
import { useDispatch } from "react-redux";
import { deletePosition } from "../../../../Redux/Actions/Positions.action";
import { CustomModal } from "../../../Shared/Modals/ModalCustom/CustomModal";

interface Props {
  showModal: any;
  setShowModal: any;
  selectedPosition: any;
  onlySee: any;
}

export const DeletePositionModal = ({
  showModal,
  setShowModal,
  selectedPosition,
  onlySee,
}: Props) => {
  const dispatch = useDispatch();
  const [employeesWithPosition, setEmployeesWithPosition] = useState([]);
  const [selectedEmployeeIds, setSelectedEmployeeIds] = useState<number[]>([]);
  const [confirmUnassign, setConfirmUnassign] = useState(false);
  const [selectAll, setSelectAll] = useState(false);

  const handleDelete = () => {
    removeInternalPosition(selectedPosition.id).then(() => {
      toast.success("Eliminado correctamente");
      setShowModal(false);
      dispatch(deletePosition(selectedPosition));
    });
  };

  const handleClose = () => {
    setShowModal(false);
  };

  useEffect(() => {
    getAllEmployeesWithInternalPosition(selectedPosition.id).then(
      (response) => {
        setEmployeesWithPosition(response);
      }
    );
  }, [selectedPosition]);

  const handleCheckboxChange = (employeeId: number) => {
    setSelectedEmployeeIds((prevSelected) => {
      if (prevSelected.includes(employeeId)) {
        return prevSelected.filter((id) => id !== employeeId);
      } else {
        return [...prevSelected, employeeId];
      }
    });
  };

  const handleSelectAllChange = () => {
    if (selectAll) {
      setSelectedEmployeeIds([]);
    } else {
      setSelectedEmployeeIds(
        employeesWithPosition.map((employee: any) => employee.id)
      );
    }
    setSelectAll(!selectAll);
  };

  const handleSelectedDelete = () => {
    setConfirmUnassign(true);
  };

  const handleConfirmUnassign = () => {
    unnasignInternalPosition(selectedEmployeeIds).then(() => {
      setSelectedEmployeeIds([]);
      setConfirmUnassign(false);
      getAllEmployeesWithInternalPosition(selectedPosition.id).then(
        (response) => {
          setEmployeesWithPosition(response);
        }
      );
      toast.success("Empleados desasignados correctamente");
    });
  };

  const handleCancel = () => {
    if (confirmUnassign) {
      setConfirmUnassign(false);
    } else {
      setShowModal(false);
    }
  };

  return (
    <CustomModal
      body={
        <div className="modal_body">
          {confirmUnassign ? (
            <div>
              <span>
                ¿Está seguro de que quiere desasignar el rol a los usuarios
                seleccionados?
              </span>
            </div>
          ) : (
            <>
              {employeesWithPosition.length > 0 ? (
                <span className="modal_body_span">
                  Este puesto está asignado a los siguientes empleados. Para
                  poder eliminarlo debe desasignar el rol a todos ellos.
                </span>
              ) : (
                <span className="modal_body_span">
                  Este puesto no tiene usuarios asignados
                </span>
              )}

              {employeesWithPosition.length > 0 && (
                <div className="all_delete">
                  <div className="checkbox_all_div">
                    <input
                      type="checkbox"
                      checked={selectAll}
                      onChange={handleSelectAllChange}
                    />
                    <label className="checkbox_all">Todos</label>
                  </div>
                  {selectedEmployeeIds.length > 0 && (
                    <div className="delete-icon" onClick={handleSelectedDelete}>
                      Desasignar
                    </div>
                  )}
                </div>
              )}

              <p className="list-button">
                <ul className="employees_list">
                  {employeesWithPosition?.map((employee: any) => {
                    return (
                      <li key={employee.id}>
                        <input
                          type="checkbox"
                          onChange={() => handleCheckboxChange(employee.id)}
                          checked={selectedEmployeeIds.includes(employee.id)}
                        />
                        {employee.display_name}
                      </li>
                    );
                  })}
                </ul>
              </p>
              {employeesWithPosition.length > 0 ? (
                <></>
              ) : (
                <>
                  {!onlySee && (
                    <span>
                      <strong>¿Está seguro de que quiere eliminarlo?</strong>
                    </span>
                  )}
                </>
              )}
            </>
          )}

          <div className="modal_body_buttons">
            <button className="modal_button" id="cancel" onClick={handleCancel}>
              Cancelar
            </button>
            {confirmUnassign && (
              <button
                className="modal_button"
                id="unassign"
                onClick={handleConfirmUnassign}
              >
                Desasignar
              </button>
            )}
            {employeesWithPosition.length <= 0 && !onlySee && (
              <button
                className="modal_button"
                id="delete"
                onClick={handleDelete}
              >
                Eliminar
              </button>
            )}
          </div>
        </div>
      }
      show={showModal}
      onChange={handleClose}
      title={selectedPosition.name}
    />
  );
};
