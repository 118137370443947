import { SyntheticEvent, useEffect, useState } from "react";
import * as yup from "yup";
import { ITags } from "../../../../interface/services";
import { getAllTags } from "../../../../Services/TagServices";
import { AedasLoading } from "../../../Shared/AedasLoading/AedasLoading";
import { DataGridEditabled } from "../../../Shared/DataGridEditabled/DataGridEditabled";
import { TableTags } from "./TableTags";
import { CustomModal } from "../../../Shared/Modals/ModalCustom/CustomModal";
import { EditPeopleTags } from "../../../Shared/Modals/EditPeopleTags/EditPeopleTags";
import { getAllEmployeeTags } from "../../../../Services/EmployeeTagService";

export const schema = yup.object().shape({
  name: yup.string().required(),
});

export const Tags = () => {
  const [openModal, setOpenModal] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [title, setTitle] = useState("");
  const [tags, setTags] = useState<ITags[]>([]);
  const [employeeTags, setEmployeeTags] = useState([]);
  const [selectedEmployeeTag, setSelectedEmployeeTag] = useState([]);

  const tableTags = new TableTags(setIsLoading);

  useEffect(() => {
    if (isLoading || !openModal) {
      let promises = [];
      promises.push(getAllTags().then(setTags));
      promises.push(getAllEmployeeTags().then(setEmployeeTags));
      Promise.all(promises).then(() => {
        setIsLoading(false);
      });
    }
  }, [isLoading, openModal]);

  const openModalOnClickCell = (e: any) => {
    setTitle(e?.row?.name);
    const employeeTagRow = employeeTags.filter(
      (etag: any) => etag.id_tag === e.row.id
    );
    setSelectedEmployeeTag(employeeTagRow);
    setOpenModal(true);
  };

  return (
    <>
      {isLoading ? (
        <AedasLoading />
      ) : (
        <>
          <CustomModal
            size="xl"
            title={title}
            show={openModal}
            onChange={() => setOpenModal(!openModal)}
            body={
              <EditPeopleTags
                employeeTagRow={selectedEmployeeTag}
                setModal={setOpenModal}
              ></EditPeopleTags>
            }
          ></CustomModal>
          <DataGridEditabled
            editMode="row"
            columns={tableTags.columns}
            handleSave={(e: SyntheticEvent) => {
              tableTags.handleSave(e);
            }}
            rows={tags}
            openModalOnClickCell={(event: any) => openModalOnClickCell(event)}
            showHeader={true}
            singleIcon={true}
            showAddIcon={true}
            title={""}
          ></DataGridEditabled>
        </>
      )}
    </>
  );
};
