import { useEffect, useState } from "react";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import DashboardIcon from "@mui/icons-material/Dashboard";
import { List } from "@mui/material";
import "./LisHeader.css";
import {
  IMenu,
  PropsMainListItems,
  PropsNavItem,
} from "../../../interface/header";

export function MainListItems(props: PropsMainListItems) {
  const data = props.menu ?? [];

  return (
    <>
      <List component={"nav"}>
        {data
          .filter((item: IMenu) => item.canView === true)
          .map((e: IMenu, i: number) => {
            return (
              <NavItem
                key={i}
                item={e}
                setOpen={props.setOpen}
                open={props.open}
              ></NavItem>
            );
          })}
      </List>
    </>
  );
}

function NavItem(props: PropsNavItem) {
  const { item, open } = props;
  const [selected, setSelected] = useState<boolean>(false);

  useEffect(() => {
    if (window.location.pathname === item.localPath) {
      setSelected(true);
    }
    if (window.location.pathname !== item.localPath) {
      setSelected(false);
    }
  }, [window.location.pathname]);

  const handleNavItem = () => {
    item.onClick(item.localPath);
  };
  return (
    <>
      <ListItemButton
        className={selected && open ? "selectedItem" : ""}
        onClick={() => handleNavItem()}
      >
        <ListItemIcon
          className={
            selected && !open
              ? "selected selectedBar"
              : selected
              ? "selected"
              : ""
          }
          style={{ fill: selected ? "var(--secondary-color)" : "" }}
        >
          <div>
            <div className="d-flex justify-content-center">
              {item.icon ?? <DashboardIcon />}
            </div>
            {!open ? (
              <div style={{ width: "50px", paddingTop: "5px" }}>
                <h1 className={`shortIcon ${selected ? "shortName" : ""}`}>
                  {item.shortName}
                </h1>
              </div>
            ) : (
              <></>
            )}
          </div>
        </ListItemIcon>

        <ListItemText
          style={{ color: selected ? "var(--secondary-color)" : "" }}
          primary={item.name}
        />
      </ListItemButton>
    </>
  );
}
