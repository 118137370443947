import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import {
  ICompany,
  IDraftOrgChart,
  IHistoricOrgChart,
} from "../interface/services";
import { getCompanyByID } from "../Services/CompanyService";
import { getDraftsOrgCharts } from "../Services/OrganizationChartService";

export function useDraftOrHistory(
  isLoading: boolean,
  setLoading: React.Dispatch<React.SetStateAction<boolean>>,
  company_id: string
) {
  const [company, setCompany] = useState<ICompany | null>(null);
  const [draftsOrgCharts, setDraftsOrgCharts] = useState<IDraftOrgChart[]>([]);
  const [historicalDrafts, setHistoricalDrafts] = useState<IHistoricOrgChart[]>(
    []
  );

  useEffect(() => {
    let promises = [];
    promises.push(
      getDraftsOrgCharts(company_id).then((response: IDraftOrgChart[]) => {
        setDraftsOrgCharts(response);
      })
    );

    promises.push(
      getCompanyByID(company_id).then((response: ICompany) => {
        setCompany(response);
      })
    );

    // promises.push(
    //   getHistoricOrgCharts(company_id).then((response: IHistoricOrgChart[]) => {
    //     setHistoricalDrafts(response);
    //   })
    // );

    Promise.all(promises)
      .then(() => {
        setLoading(false);
      })
      .catch((error) => {
        if (error.status === 404) {
          setLoading(false);
        } else {
          toast.error("Ha habido un error");
        }
      });
  }, [isLoading]);

  return {
    draftsOrgCharts,
    historicalDrafts,
    company,
  };
}
