import Button from "@mui/material/Button";
import { useHistory } from "react-router-dom";
import "./orgChartError.css";

export const OrgChartError = () => {
  const history = useHistory();

  return (
    <div className="error_container">
      <div className="error_box">
        <h1 className="error_title">¡Lo sentimos!</h1>
        <h1 className="error_body">Este organigrama no esta disponible</h1>
        <Button
          onClick={() => history.push("/organigrama")}
          style={{ color: "white", background: "var(--blue-lis)" }}
        >
          Volver al Home
        </Button>
      </div>
    </div>
  );
};
