import axios from "axios";
import { configAuth } from "../Utils/headersConfig";

export const getAllLocations = async () => {
    return await axios
      .get(
        process.env.REACT_APP_API_BACKEND + "/api/v1" + "/location/",
        configAuth()
      )
      .then((res) => res.data);
  };
  
  export const getLocationID = async (id: string) => {
    return await axios
      .get(
        process.env.REACT_APP_API_BACKEND + "/api/v1" + "/location/" + id,
        configAuth()
      )
      .then((res) => res.data);
  };
  
  export const createLocation = async (company: any) => {
    return await axios
      .post(
        process.env.REACT_APP_API_BACKEND + "/api/v1" + "/location",
        company,
        configAuth()
      )
      .catch((error) => {
        console.log(error);
      });
  };
  
  export const updateLocation = async (data:any) => {
    return await axios.put(
      process.env.REACT_APP_API_BACKEND + "/api/v1" + "/location",
      data,
      configAuth()
    );
  };
  
  export const removeLocation = async (id: any) => {
    return await axios
      .delete(
        process.env.REACT_APP_API_BACKEND + "/api/v1" + "/location/" + id,
        configAuth()
      )
  };
  
  export const getAllEmployeesWithLocation = async (id:any) => {
    return await axios
    .get(
      process.env.REACT_APP_API_BACKEND + "/api/v1" + "/employee/location/" + id,
      configAuth()
    )
    .then((res) => res.data);
  }

  export const unnasignLocation = async (employees:any) => {
    const employeesArray = {
      "employees_ids" : employees
    }
    return await axios
      .post(
        process.env.REACT_APP_API_BACKEND + "/api/v1" + "/employee/unassignLocation/",
        employeesArray,
        configAuth()
      )
      .catch((error) => {
        console.log(error);
      });
  }