import { SyntheticEvent, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { useDraftOrHistory } from "../../../../hooks/useDraftsOrHistory";
import { AedasLoading } from "../../../Shared/AedasLoading/AedasLoading";
import { DataGridEditabled } from "../../../Shared/DataGridEditabled/DataGridEditabled";
import { CustomModal } from "../../../Shared/Modals/ModalCustom/CustomModal";
import { AddDraftModal } from "./AddDraftModal/AddDraftModal";
import { TableDrafts } from "./TableDrafts";
import { useToggle } from "../../../../hooks/useToggle";

export const ShowDrafts = () => {
  const { company_id }: { company_id: string } = useParams();

  const history = useHistory();
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [openModalShow, setOpenModalShow] = useToggle(false);
  const { company, draftsOrgCharts, historicalDrafts } = useDraftOrHistory(
    isLoading,
    setIsLoading,
    company_id
  );
  const tableDrafts = new TableDrafts(company, history);

  const onCellClick = () => {};

  return (
    <>
      <h1 className="main-title">Borradores</h1>
      {isLoading ? (
        <AedasLoading />
      ) : (
        <div className="px-4 " style={{ height: "calc(100vh - 10%)" }}>
          <DataGridEditabled
            onlyEdit={false}
            showHeader={true}
            singleIcon={false}
            showAddIcon={true}
            rows={draftsOrgCharts}
            columns={tableDrafts.columns}
            edit={true}
            openModalOnClickCell={() => null}
            openModal={setOpenModalShow}
            onCellClick={onCellClick}
            getRowId={(row: any) => row.mongo_id}
            handleSave={(e: SyntheticEvent) => {
              tableDrafts.handleSave(e);
              setIsLoading(!isLoading);
            }}
          ></DataGridEditabled>

          <CustomModal
            size="xl"
            show={openModalShow}
            onChange={() => {
              setOpenModalShow();
            }}
            title="Crear Borrador"
            body={
              <AddDraftModal
                show={openModalShow}
                onChange={() => {
                  setOpenModalShow();
                  setIsLoading(true);
                }}
                onFinishChangue={() => {
                  setIsLoading(false);
                }}
                selectedCompany={company}
                draftsOptions={draftsOrgCharts}
                historicalDrafts={historicalDrafts}
              />
            }
          ></CustomModal>
        </div>
      )}
    </>
  );
};
