
const initialState = {
    locations: null,
  };
  export const locationsReducer = (state: any = initialState, action: any) => {
    switch (action.type) {
      case "@locations/all":
        return {
          ...state,
          locations: action.payload.locations
        };
      case "@locations/add":
          return {
              ...state,
              locations: [...state.locations,action.payload.newLocation.data]
          }
      case "@locations/edit":
        const {id, name} = action.payload.updatedLocation;
        const locations = state.locations.map((location:any) => {
          if (location.id === id) {
            return { ...location, name };
          }
          return location;
        });
        return {
          ...state,
          locations: locations
        }
      case "@locations/delete":
        const deleteId = action.payload.deletedLocation.id;
        const filteredLocations = state.locations.filter(
          (location:any) =>{
            return location.id !== deleteId
          }
        );
        
        return {
          ...state,
          locations: filteredLocations
        };
      default:
        return state;
    }
  };
  