import {
  GridColDef,
  GridRenderEditCellParams,
  GridValueGetterParams,
} from "@mui/x-data-grid";
import {
  ICompany,
  IDelegation,
  IEmployeeTag,
  ITags,
} from "../../../../interface/services";
import { updateInDT } from "../../../../Services/EmployeeConfig";
import CheckBox from "@mui/material/Checkbox";

export class TableEmployee {
  constructor(
    public company: ICompany[],
    public delegation: IDelegation[],
    public employeetags: IEmployeeTag[],
    public tags: ITags[]
  ) {}

  columns: GridColDef[] = [
    {
      field: "id",
      headerName: "ID",
      width: 9,
      hide: true,
      editable: false,
    },

    {
      field: "display_name",
      headerName: "Empleado",
      type: "string",
      width: 200,
      editable: false,
      flex: 1,
    },

    {
      field: "id_company",
      headerName: "Empresa",
      type: "string",
      width: 200,
      editable: false,
      flex: 1,
      valueGetter: (params: GridRenderEditCellParams) => {
        const comp: ICompany | undefined = this.company.find(
          (c: ICompany) => c.id === params.row.id_company
        );
        return comp ? comp.name : "";
      },
    },

    {
      field: "delegacion_provincial",
      headerName: "Delegación",
      width: 200,
      editable: false,
      flex: 1,
      valueGetter: (params: GridRenderEditCellParams) => {
        if (!params.row.delegacion_provincial) {
          return "Sin delegación";
        }
        const delegacion: IDelegation | undefined = this.delegation.find(
          (p: IDelegation) => p.id === params.row.delegacion_provincial
        );
        return delegacion ? delegacion.name : "";
      },
    },
    {
      field: "delegacion_provincial",
      headerName: "Puesto Interno",
      width: 200,
      editable: false,
      flex: 1,
      // valueGetter: (params: GridRenderEditCellParams) => {
      //   if (!params.row.internal_position) {
      //     return "Sin puesto";
      //   }
      //   const internal_position: any = this.internal_position.find(
      //     (p) => p.id === params.row.internal_position
      //   );
      //   return delegacion ? delegacion.name : "";
      // },
    },
    {
      field: "active_status",
      type: "string",
      headerName: "Estado",
      editable: false,
      width: 200,
      flex: 1,
      valueGetter: (params: GridRenderEditCellParams) => {
        const STATES: any = {
          0: "Activado",
          1: "Falta DNI para Activo",
          2: "Desactivado",
        };
        const STATE_DEFAULT = "";
        const state = STATES[params.row.active_status] || STATE_DEFAULT;
        return state;
      },
    },
    {
      field: "email",
      type: "string",
      headerName: "Email",
      editable: false,
      width: 200,
      flex: 1,
    },

    {
      field: "telephone",
      type: "string",
      headerName: "Teléfono",
      editable: false,
      width: 200,
      flex: 1,
    },
    {
      field: "in_dt",
      type: "boolean",
      headerName: "¿En DT?",
      editable: true,
      width: 200,
      flex: 1,
      valueGetter: (params: GridValueGetterParams) => {
        return params.row.in_dt;
      },

      renderEditCell: (params: GridRenderEditCellParams) => {
        const handleChange = (e: any) => {
          const result = (params.row.in_dt = e.target.checked);
          updateInDT(params.row.id);
          return result;
        };
        return (
          <CheckBox
            checked={params?.row?.in_dt}
            onChange={handleChange}
          ></CheckBox>
        );
      },
    },
  ];
}
