import moment from "moment";
import { Button } from "@mui/material";
import {
  GridColDef,
  GridRenderEditCellParams,
  GridValueGetterParams,
} from "@mui/x-data-grid";
import { toast } from "react-toastify";
import { SyntheticEvent } from "react";
import { ICompany, IDraftOrgChart } from "../../../../interface/services";
import { removeDraft } from "../../../../Services/OrganizationChartService";

export class TableDrafts {
  constructor(public company: ICompany | null, public history: any) {}

  columns: GridColDef[] = [
    {
      field: "id_company",
      headerName: "Empresa",
      type: "string",
      renderCell: (params: GridRenderEditCellParams) => {
        return <div>{this.company ? this.company?.name : ""}</div>;
      },
      width: 200,
      editable: false,
      align: "left",
      flex: 1,
    },
    {
      field: "version",
      headerName: "Última Edicion",
      renderCell: (params: GridValueGetterParams) => {
        let date = moment(new Date(params.row.version)).format(
          "DD-MM-YYYY HH:mm"
        );
        return <div>{date}</div>;
      },
      type: "string",
      width: 200,
      editable: false,
      align: "left",
      flex: 1,
    },
    {
      field: "actions",
      headerName: "Acciones",
      width: 500,
      renderCell: (params: GridValueGetterParams) => {
        return (
          <>
            <Button
              onClick={(e: SyntheticEvent) =>
                this.handleRoute(params.row.mongo_id)
              }
              color="primary"
              className="org_Button"
            >
              Editar
            </Button>
          </>
        );
      },
    },
  ];

  handleRoute = (mongoID: string) => {
    this.history.push(
      `/organigramas/borradores/${this.company?.id}/${mongoID}`
    );
  };
  handleRemove = (draftsOrgChart: IDraftOrgChart[]) => {
    draftsOrgChart.map(async (draft: IDraftOrgChart) => {
      removeDraft(draft.mongo_id, false).catch((error) => toast.error);
    });
  };
  handleSave = (data: any) => {
    if (data.removed.length > 0) {
      this.handleRemove(data.removed);
    }
  };
}
