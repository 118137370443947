import * as yup from "yup";
import { useState } from "react";
import { useFormik } from "formik";
import { useMsal } from "@azure/msal-react";
import "./Login.css";
import { loginRequest } from "../../../authConfig";
import { Button } from "@mui/material";

const validationSchema = yup.object({
  email: yup
    .string()
    .email("Introduzca un email valido. Ej: ejemplo@gmail.com")
    .required("Este campo es requerido"),
  password: yup
    .string()
    .min(8, "La contraseña debe ser de al menos 8 caracteres")
    .required("La contraseña es requerida"),
});

export function LoginForm(props: any) {
  const { instance } = useMsal();
  const [isLoading, setIsLoading] = useState(false);
  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
    },
    validationSchema: null,
    onSubmit: async (values) => {
      instance.loginRedirect(loginRequest).catch((e) => {
        console.error(e);
      });
    },
  });

  return (
    <>
      <form onSubmit={formik.handleSubmit}>
        <Button
          color="primary"
          className="loginButton"
          variant="contained"
          fullWidth
          type="submit"
        >
          Entrar
        </Button>
      </form>
    </>
  );
}
