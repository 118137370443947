import axios from "axios";
import { configAuth } from "../Utils/headersConfig";

export const getAllInternalPositions = async () => {
  return await axios
    .get(
      process.env.REACT_APP_API_BACKEND + "/api/v1" + "/internal_position/",
      configAuth()
    )
    .then((res) => res.data);
};

export const getInternalPositionID = async (id: string) => {
  return await axios
    .get(
      process.env.REACT_APP_API_BACKEND + "/api/v1" + "/internal_position/" + id,
      configAuth()
    )
    .then((res) => res.data);
};

export const createInternalPosition = async (company: any) => {
  return await axios
    .post(
      process.env.REACT_APP_API_BACKEND + "/api/v1" + "/internal_position",
      company,
      configAuth()
    )
    .catch((error) => {
      console.log(error);
    });
};

export const updateInternalPosition = async (data:any) => {
  return await axios.put(
    process.env.REACT_APP_API_BACKEND + "/api/v1" + "/internal_position",
    data,
    configAuth()
  );
};

export const removeInternalPosition = async (id: any) => {
  return await axios
    .delete(
      process.env.REACT_APP_API_BACKEND + "/api/v1" + "/internal_position/" + id,
      configAuth()
    )
};

export const getAllEmployeesWithInternalPosition = async (id:any) => {
  return await axios
  .get(
    process.env.REACT_APP_API_BACKEND + "/api/v1" + "/employee/internal_position/" + id,
    configAuth()
  )
  .then((res) => res.data);
}

export const unnasignInternalPosition = async (employees:any) => {
  const employeesArray = {
    "employees_ids" : employees
  }
  return await axios
    .post(
      process.env.REACT_APP_API_BACKEND + "/api/v1" + "/employee/unassignInternalPosition/",
      employeesArray,
      configAuth()
    )
    .catch((error) => {
      console.log(error);
    });
}

export const getAllPositions = async () => {
  return await axios
    .get(
      process.env.REACT_APP_API_BACKEND + "/api/v1" + "/promociona/positions",
      configAuth()
    )
    .then((res) => res.data);
}