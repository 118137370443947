import { useCallback, useState } from "react";

/**
 * @description Hook para establecer el contrario de un estado, Casos de uso: Modales, Open/Close Menu..
 * @param {boolean} initialState Parametro para establecer el valor inicial, por defecto es false.
 * @returns {[boolean,function]} Devuelve el estado inicial y la funcion que setea el contrario del estado.
 */
export const useToggle = (
  initialState: boolean = false
): [boolean, () => void] => {
  const [state, setState] = useState(initialState);
  const toggle = useCallback(() => setState((state) => !state), []);
  return [state, toggle];
};
