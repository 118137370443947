export const getPositions = (
  positions: any
) => {
  return {
    type: "@positions/all",
    payload: { positions: positions },
  };
};

export const addPosition = (
    newPosition:any
) => {
    return {
        type: "@positions/add",
        payload: { newPosition: newPosition },
      };
}

export const editPosition = (updatedPosition:any) => {
  
  return {
    type: "@positions/edit",
    payload: { updatedPosition: updatedPosition.data },
  };
}

export const deletePosition = (deletedPosition: any) => {
  
  return {
    type: "@positions/delete",
    payload: { deletedPosition: deletedPosition},
  };
}