import { useState } from "react";
import Button from "@mui/material/Button";
import { TableEmployee } from "./TableEmployee";
import { DataGrid } from "@mui/x-data-grid";
import { useEmployeesTable } from "../../../../hooks/useEmployeesTable";
import { AedasLoading } from "../../../Shared/AedasLoading/AedasLoading";
import { CustomModal } from "../../../Shared/Modals/ModalCustom/CustomModal";
import { EditEmployee } from "../../../Shared/Modals/EditEmployee/EditEmployee";
import { useToggle } from "../../../../hooks/useToggle";
import { refreshEmployees } from "../../../../Services/EmployeeConfig";

export const Employees = () => {
  const [update, setUpdate] = useToggle(false);
  const [openModal, setOpenModal] = useToggle();
  const [selectedRow, setSelectedRow] = useState(null);
  const [selectedCompany, setSelectedCompany] = useState(null);
  const [selectedDelegation, setSelectedDelegation] = useState(null);
  const [selectedEmployeeTag, setSelectedEmployeeTag] = useState(null);
  const {
    companies,
    delegations,
    employees,
    setEmployees,
    employeetags,
    tags,
    departments,
    isLoading,
  } = useEmployeesTable(update, null);
  const tableEmployee = new TableEmployee(
    companies,
    delegations,
    employeetags,
    tags
  );
  const handleUpdateEmployees = () => {
    refreshEmployees();
  };
  const onCellClick = (e: any) => {
    setSelectedRow(e.row);
    setOpenModal();

    const companyRow: any = companies.find(
      (c: any) => c.id === e.row.id_company
    );
    const delegationRow: any = delegations.find(
      (d: any) => d.id === e.row.delegacion_provincial
    );

    const employeeTagRow: any = employeetags.filter(
      (t: any) => t.id_employee === e.row.id
    );

    setSelectedCompany(companyRow);
    setSelectedDelegation(delegationRow);
    setSelectedEmployeeTag(employeeTagRow);
  };

  return (
    <>
      {isLoading || employees.length <= 0 || companies.length <= 0 ? (
        <AedasLoading></AedasLoading>
      ) : (
        <>
          {/* MODAL */}
          <CustomModal
            size="xl"
            show={openModal}
            onChange={setOpenModal}
            title="Edición"
            body={
              <EditEmployee
                employee={selectedRow}
                setEmployee={setSelectedRow}
                setEmployees={setEmployees}
                selectedCompany={selectedCompany}
                selectedDelegation={selectedDelegation}
                employeeTags={selectedEmployeeTag}
                companies={companies}
                delegations={delegations}
                tags={tags}
                departaments={departments}
                show={setOpenModal}
                employees={employees}
                isLoading={setUpdate}
              ></EditEmployee>
            }
          ></CustomModal>
          {/* FIN MODAL */}
          <div className="button-container">
            <Button
              className="save_button my-2"
              color="primary"
              onClick={(e) => {
                setUpdate();
                handleUpdateEmployees();
              }}
            >
              Actualizar empleados
            </Button>
          </div>
          <div className="dataGrid-table--employees">
            <DataGrid
              onCellClick={(event: any) => {
                if (event.field !== "in_dt") {
                  onCellClick(event);
                }
              }}
              onCellEditStop={(e: any) => {
                setUpdate();
                handleUpdateEmployees();
              }}
              rows={employees}
              columns={tableEmployee.columns}
              disableSelectionOnClick={false}
              editMode="cell"
            ></DataGrid>
          </div>
        </>
      )}
    </>
  );
};
